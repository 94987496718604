@import './variables.scss';

.share-access-dropdown {
  width: 100%;
  max-width: 418px;
  background-color: #fff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1.5rem;

  .drop_title {
    color: $titleDark;
    margin-bottom: 1rem;

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 0.9rem;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .shared__members {
    margin-top: 1.2rem;
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $titleDark;
      margin-bottom: 1.9rem;
    }
    &-data {
      display: flex;
      justify-content: space-between;
      margin-top: 0.3rem;

      &_details {
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
          width: 22px;
          margin-right: 10px;
        }
        span {
          color: $titleDark;
        }
      }
      // &_remove {
      // }
    }
  }
  .shared__teams {
    margin-top: 1.2rem;
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $titleDark;
      margin-bottom: 1.9rem;
    }
    &-data {
      display: flex;
      justify-content: space-between;
      margin-top: 0.3rem;

      &_details {
        display: flex;
        align-items: center;

        span:first-child {
          margin-right: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $titleDark;
        }
        span:last-child {
          color: $fade4;
        }
      }
      // &_remove {
      // }
    }
  }
}
