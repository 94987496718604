@import 'styles/variables.scss';

.trx_card {
  display: flex;
  border: 2px solid #e3e3e3;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  color: #383838;
  cursor: pointer;
  padding: 10px 15px;

  .select_card {
    display: flex;
    justify-content: flex-end;
  }
  .card_logo {
    flex: 0.3;
    svg {
      height: 30px;
      width: 40px;
      transform: scale(1.2);
      margin-right: 18px;
    }
  }
  .card_data__number {
    font-weight: 600;
    text-transform: capitalize;
  }

  .card_status {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .card_status__button {
    font-weight: 600;
  }
  .card_status__edit {
    margin-right: 10px;
    color: $primaryColor;
    &:disabled {
      cursor: not-allowed;
    }
  }

  .card_status__delete {
    color: #db2222;
    &:disabled {
      cursor: not-allowed;
    }
  }

  .card_logo__wrapper {
    margin-left: 10px;
  }

  .card_data {
    flex: 1.7;
    display: flex;
    justify-content: space-between;
    .card_checked_icon {
      height: 15px;
      width: 15px;
      display: none;
    }
    .card_checked_icon__visible {
      display: block;
    }
  }
}

.trx_card__selected {
  border: 2px solid $primaryColor;
  background-color: #fafafe;
}
