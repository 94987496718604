@import './variables.scss';

.emmaccen {
  .editorContainer {
    .editorInputArea {
      // width: 80%;
      margin: 0px auto;
      padding-bottom: 50px;
      @media (max-width: 1000px) {
        // width: 90%;
        @media (max-width: 700px) {
          // width: 95%;
        }
      }

      .ckeditorStyles {
        height: 70vh;
        overflow-y: hidden;
        // Olayinka
        div[contenteditable='true']:hover,
        div[contenteditable='true']:focus {
          background-color: white;
        }

        // Olayinka End

        h1 {
          font-size: 3.5rem;
        }

        h2 {
          font-size: 3rem;
        }

        h3 {
          font-size: 2.5rem;
        }

        h4 {
          font-size: 2rem;
        }

        h5 {
          font-size: 1.5rem;
        }

        h6 {
          font-size: 1rem;
        }

        .ck-content {
          min-height: 400px;
          max-height: 500px;
          overflow-y: auto;
          padding: 15px 20px;
        }

        .ck-toolbar {
          position: sticky;
          top: 0;
          z-index: 9;
        }

        .ck-content,
        .ck-toolbar {
          border: 1px solid $shade1;

          a {
            color: #0c7fda;
            text-decoration: underline;
          }

          a:hover {
            cursor: pointer;
          }
        }
      }

      .email-subject-mention {
        max-width: 100%;
        height: 40px;

        .email-subject-mention__input {
          height: 40px;
          padding: 7px 11px;
        }
      }
    }
  }
}

#tags {
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding: 4px;
  border-radius: 3px;
  transition: background 0.1s ease-in-out;
}

#dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
  z-index: 1;
  padding: 10px 0;
  left: -45px;
  top: 30px;
  border-radius: 4px;
}

#dropdown_item {
  color: #0c7fda;
  padding: 4px 10px 0 10px;
  text-decoration: none;
  display: block;
  font-weight: 500;
}

#dropdown_item:hover {
  background-color: #f2f8fc;
}

.show {
  display: block !important;
}

#tags .tooltiptext {
  opacity: 0;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 75%;
  margin-left: -60px;
  font-size: 12px;
  padding: 2px 6px 0 6px;
  background: #333;
}

#tags:hover {
  background: #eaeaea;
}

#tags .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

#tags:hover .tooltiptext {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.attach-box {
  background: #fff;
  box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 24px 24px 20px 24px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 888;
}

.attach-title {
  font-family: 'Paralucent-Light';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.6px;
  color: #717171;
  text-align: left;
}

.attachments-box {
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 24px;
}

.attachment-single {
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: #fff;
  height: 48px;
  width: 313px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  overflow: hidden;
}

.attach-inner {
  display: flex;
  height: 100%;
  align-items: center;
  width: 90%;
}

.attach-icon {
  width: 10%;
}

.attach-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 8px;
  width: 90%;
}

.attach-info-text {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  color: #5a6af2;
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.attach-info-date {
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #717171;
  text-align: left;
}

.attach-remove {
  border-left: 1px solid #f1f1f1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  width: 10%;
}
