@import './variables.scss';

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  padding: 1rem 11px;
  border-right: 1px solid #eaeaea;
  z-index: 6;
  background: #fff;
  transition: width 0.4s;

  @media (max-width: 500px) {
    display: none;
  }

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;

    .sidebar__logo {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      text-decoration: none;

      .logo {
        width: 38px;
        height: 38px;
        text-align: center;
      }

      .logo-title {
        font-weight: 600;
        font-size: 21.039px;
        line-height: 22px;
        color: $primaryColor;
        margin-left: 12px;
      }
    }

    .sidebar__menu {
      transition: width 0.4s;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative; 

      .sidebar__menu__list {
        flex: 1;
        overflow-y: auto;
        padding-right: 7px;
        border-top: 1px solid #E3E7F0;
        border-bottom: 1px solid #E3E7F0;
        margin-bottom: 200px; // Add space for the chat buttons
        -webkit-overflow-scrolling: touch; // Smooth scrolling on iOS
        
        /* Customize scrollbar */
        &::-webkit-scrollbar {
          width: 4px;
        }
        
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 4px;
        }
        
        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }
        
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }

      &__item {
        display: flex;
        align-items: center;
        margin: 0.7rem 0;
        border-radius: 3.65895px;
        transition: all 0.3s ease-in-out;
        width: 100%;

        &:hover {
          background: #f2f3fe;
        }

        &.active {
          background: #f2f3fe;
          .sidebar__menu__item__icon,
          .sidebar__menu__item__text {
            color: $primaryColor;
          }
        }

        &__icon {
          width: 40px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $fade4;
          transition: all 0.3s ease-in-out;
        }

        &__text {
          font-size: 16px;
          line-height: 22px;
          transition: all 0.3s ease-in-out;
          margin-left: 12px;
          margin-right: 12px;
          padding-left: 7.32px;
        }
      }
    }
  }
}

.chat-buttons-container {
  position: absolute;
  bottom: 0px;
  left: 11px; 
  right: 11px; 
  background: white; 
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chat-with-us {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px;
  background: #273DF2;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border: none;

  &:hover {
    background: darken(#273DF2, 5%);
  }
}

.whatsapp-support {
  display: flex;
  align-items: center;
  padding: 3px;
  gap: 5px;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background-color: #E9ECFE;
  border-radius: 8px;
  
  .whatsapp-s-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    font-size: 16px;
    color: #273DF2;
    text-decoration: underline;
  }
}

.card_consult {
  display: flex;
  align-items: center;
  padding: 1px;
  margin-bottom: 8px;
  border-radius: 12px;
  border: 1px solid #E5E7EB;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  width: 100%;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #EEF2FF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    font-size: 18px;
  }
  
  .content {
    flex: 1;
    padding-left: 8px;
    
    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: #1F2937;
    }

    .content-top {
      display: flex;
      align-items: center;
    }
    
    p {
      margin: 4px 0 0;
      font-size: 14px;
      color: #6B7280;
    }
  }
  
  .arrow {
    margin-left: auto;
    padding: 0 12px;
    color: #6B7280;
  }
}
.btn-icon-only{
  
  border-radius: 4px;
  background-color: #273df2;
  margin-bottom: 1rem;
  margin-top: 1rem;
  
}
.btn-create {
  height: 34px;
  max-width: 200px;
  border-radius: 4px;
  margin-left: 0.8rem;
  padding: 8px 8px;
  background-color: #273df2;
  margin-bottom: 1rem;
  
  .create-text {
    color: #ffffff !important;
  }
}

.hideMe {
  display: none;
  -webkit-animation: hideMation 0.4s forwards;
  animation: hideMation 0.4s forwards;
}

.revealMe {
  display: block;
  -webkit-animation: revealMation 0.6s forwards;
  animation: revealMation 0.6s forwards;
}