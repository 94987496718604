/* Stripo custom styles */
.speditor_builder {
  min-height: 100vh;
  text-align: center;
  width: 100%;
  position: relative;

  &__loading_state {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
  }

  &__top_bar_cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 34px;

    &__ltr,
    &__rtl,
    &__md {
      display: flex;
      align-items: center;
      column-gap: 8px;

      #btn--type-outline_default,
      #btn--type-primary_default {
        width: fit-content;
        padding: 8px;
      }
    }

    &__ltr {
      .undoredo_cta {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .undoButton {
          margin-left: 16px;
        }
      }
    }
  }

  &__editor_view {
    width: 100%;
    display: flex;
    align-items: flex-start;

    #stripoSettingsContainer {
      width: 400px;
      font-family: 'Paralucent-Light';
    }

    #stripoSettingsContainer.hideSettingsContainer {
      display: none;
    }

    #stripoPreviewContainer {
      width: calc(100% - 400px);
      font-family: 'Paralucent-Light';
      background-color: white;
    }

    #stripoPreviewContainer.fullWidthPreviewContainer {
      width: 100%;
    }
  }

  // #changeHistoryLink {
  //   cursor: pointer;
  // }
  .notification-zone {
    position: fixed;
    width: 400px;
    z-index: 99999;
    right: 20px;
    bottom: 80px;
  }

  .isLoading {
    display: inline-block;
    border-radius: 50%;
    border: 0.17rem solid;
    border-color: #fff;
    width: 1em;
    height: 1em;
    border-right-color: transparent;
    animation: loadingSpinner 1.5s linear infinite;
  }

  @keyframes loadingSpinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

/* Externl Preview popup */
#externalPreviewPopup {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  font-family: sans-serif;
}

.modal-container {
  background-color: #f6f6f6;
  border-radius: 17px 17px 30px 30px;
  width: 1120px;
  margin: 10px auto;
}

.modal-header-container {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  font-family: 'Paralucent-Medium';
}

.modal-title {
  margin: 0;
  font-size: 18px;
  color: rgb(85, 85, 85);
}

.modal-close-button {
  cursor: pointer;
  background: black;
  border: 0;
  float: right;
  font-size: 21px;
  font-weight: bold;
  opacity: 0.2;
  padding: 2px 12px;
  border-radius: 5px;
  color: #fff;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.preview-container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.preview-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.preview-col-sm-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.preview-col-sm-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.preview-col-sm-4,
.preview-col-sm-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.esdev-desktop-device-screen,
.esdev-mobile-device-screen {
  background-color: #ffffff;
}

.esdev-desktop-device {
  background: #ffffff;
  border: 2px solid #e8e8e8;
  font-size: 0;
  position: relative;
  border-radius: 10px 10px 3px 3px;
}

.esdev-desktop-device:before {
  content: '―–';
  display: block;
  background: #e8e8e8;
  position: absolute;
  margin-top: 16px;
  border-radius: 10px;
  margin-left: 47px;
  font-size: 73px;
  color: #e8e8e8;
  line-height: 0;
  font-family: sans-serif;
}

.esdev-email-window-panel {
  padding: 15px 15px 12px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
}

.esdev-email-user-avatar {
  float: left;
  color: #e8e8e8 !important;
  text-shadow: none !important;
  font-size: 16px !important;
}

.esdev-email-subject {
  padding-left: 13px;
  padding-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.esdev-mobile-device {
  position: relative;
  height: 698px;
  width: 344px;
  border-radius: 40px;
  padding: 50px 10px 60px;
  background: #fff;
  border: 2px solid #e8e8e8;
}

.esdev-mobile-device-screen {
  box-shadow: 0 0 0 1px #e8e8e8;
  border-radius: 2px;
  overflow: hidden;
  height: 568px;
}

.esdev-mobile-device-screen .esdev-mail-bottom-bar {
  margin-top: -5px;
}

.esdev-mobile-device:after {
  position: absolute;
  bottom: 13px;
  left: 50%;
  margin-left: -24px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  border: 2px solid #e8e8e8;
  content: ' ';
  display: block;
}

.esdev-mobile-device:before {
  position: absolute;
  top: 22px;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  background: #e8e8e8;
  height: 8px;
  border-radius: 20px;
  content: ' ';
  display: block;
}

/* Externl Preview popup */

/* Stripo custom styles */
.esdev-app .esdev-option-panel .panel-default>.panel-heading {
  padding: 12px 2rem !important;
  text-align: left;
}

.esdev-app .collapse.in {
  background-color: white;
  padding: 1rem 2rem;
  border-bottom: 1px solid var(--panels-border-color)
}

.esdev-app .esdev-option-panel .thumbnail.esdev-block {
  padding: 0 !important;
  height: 63px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  border-radius: 0;
}

.esdev-app .esdev-option-panel .nav-tabs>li>a {
  color: #383838 !important;
}

.esdev-app .esdev-option-panel .nav-tabs>li.active>a {
  color: lighten($color: #383838, $amount: 50) !important;
}

.stripo-preview-frame .es-wrapper-color {
  background-color: white !important;
}

.actionBtn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background-color: transparent;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: 1px solid #8e8e8e;
  color: #8e8e8e;
}

.actionBtn.red {
  color: #EC615B;
  border: 1px solid #EC615B;
}

.actionBtn.blue {
  color: #273DF2;
  border: 1px solid #273DF2;
  background-color: transparent !important;
}

.actionBtn.nb,
.actionBtn.nb p,
.actionBtn.blue p {
  color: #273DF2;
  border: 0 !important;
  background-color: transparent !important;
}

.actionBtn.solid {
  color: white;
  border: 1px solid #273DF2;
  background-color: #273DF2;
}

.actionBtn.solid p {
  color: white;
}

.actionBtn p {
  margin: 0 !important;
  color: #8e8e8e;
}

.beta-mode {
  padding: 0rem .5rem;
  background-color: #ffb27d;
  height: fit-content;
  color: black;
  font-size: .6rem;
  border-radius: 4px;
  width: fit-content;
  font-family: 'Paralucent-Light';
  letter-spacing: 1px;
}

.stripo-temp .ant-modal-body {
  max-height: 85vh;
  overflow: auto;
}