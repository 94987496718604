@import './variables.scss';
.emmaccen {
  .ant-modal-body {
    form {
      input[type='text'],
      input[type='email'],
      input[type='number'] {
        border: 1px solid $shade1;
        border-radius: 4px;
        height: 45px;
      }
    }
  }
  .pad30 {
    margin-top: 30px;
  }
  .addNewContactModal,
  .addNewTeamMember,
  .addNewRecipient {
    .ant-row.ant-form-item {
      margin-bottom: 15px;
    }
    .ant-form-vertical .ant-form-item-label {
      // padding-bottom: 0;

      label {
        height: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #717171;
        font-family: 'Inter';
        font-size: 14px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      color: $fade5;
    }

    .ant-alert-info {
      background-color: #d0e4ff;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      font-family: 'Inter';
      border: none !important;
      border-radius: 0px !important;
      .ant-alert-message {
        color: #1677ff !important;
      }
    }

    .hideNull {
      display: none;
    }

    .showNull {
      display: block;
    }

    .csvUploadDoneBtn {
      cursor: none !important;
    }

    .actionBtns {
      button {
        min-width: 100px;
      }
      & button:first-child {
        border: 1px solid $shade1;
      }
    }
    .ant-select-selector {
      font-weight: 400;
      font-size: 14px;
      color: $fade555;
      //   background-color: $lightBorder;
    }
    .newRecipientsDir {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      span {
        font-weight: bold;
      }
    }
    .uploadContact {
      span {
        font-weight: 500;
        font-size: 16px;
      }
      button {
        border: 1px solid $shade1;
        min-width: 100px;
      }
    }
  }
  .createContactGroupModal {
    .title {
      font-weight: 600;
      font-size: 18px;
      margin: 30px 0px 20px 0px;
      color: $fade5;
    }
    .hint {
      font-weight: 500;
      font-size: 14px;
      color: $fade5;
      margin-top: 20px;
    }
  }
  .contact_upload_stats {
    & > .title {
      font-weight: 600;
      font-size: 18px;
      margin: 10px 0px 20px 0px;
      color: $fade5;
    }
    .upload_info {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
      // justify-content: center;
      grid-gap: 1rem;
      padding: 1rem;
      box-sizing: border-box;

      & > div {
        text-align: center;
        h2 {
          font-weight: 700;
          font-size: 20px;
          color: $descriptionTextColor;
          margin-bottom: 10px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: $fade555;
        }
      }
    }
    .insight_stats {
      & > div {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      }
      .items {
        margin-top: 10px;
        box-sizing: border-box;
        span {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 8px;
        }
        p {
          margin-right: 8px;
        }
        h3 {
          font-weight: 600;
          color: $fade5;
        }
      }
    }
    .close_btn {
      width: 100%;
      text-align: center;
      margin-top: 1.5rem;

      button {
        min-width: 120px;
      }
    }
  }
  &.deleting-email-modal,
  &.deleting-campaign-modal,
  &.deleting-instantEmail-modal,
  &.create-instantEmail-modal,
  &.create-campaign-modal {
    .ant-modal-content {
      padding: 24px 32px;
    }
  }

  &.create-instantEmail-modal {
    .title {
      text-align: center;
      margin-bottom: 18px;
    }

    .ant-modal-body {
      padding: 0;
    }

    .info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 16px;
      padding-right: 5px;
      gap: 10px;
      background-color: #f9f5fe;
      margin-bottom: 40px;
      border-radius: 8px;

      .modalText {
        color: #717171;
        max-width: 243px;
      }

      img {
        width: 69px;
      }
    }

    .email-title {
      display: flex;
      flex-direction: column;

      label {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 1.25rem;
        color: #717171;
      }
    }

    .ant-form #btn--type-primary_lg {
      width: 100%;
      margin-top: 21px;
      margin-bottom: 26px;
    }
    // .ant-modal-footer {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin-top: 0;
    //   margin-bottom: 25px;

    //   .ant-btn-default {
    //     display: none;
    //   }

    //   .maildrip_btn {
    //     width: 100%;
    //     height: 40px;
    //     margin-inline-start: 0;
    //     text-align: center;
    //     font-size: 1rem;
    //     line-height: 1.37rem;
    //   }
    // }
  }
}
.cust-modal-class.embedded_form {
  .ant-modal-body {
    // padding: 0;
    // padding-bottom: 1rem;
  }

  .embedded_form-child {
    .step1,
    .step2 {
      margin: 2rem 0;
    }
    .step_desc {
      margin-bottom: 1rem;
      span {
        color: $fade555;
        font-weight: 500;
      }
    }
    .code-block {
      position: relative;

      .copy_content {
        position: absolute;
        top: 0;
        right: 0;
      }
      .hljs.language-html {
        background-color: $fade6;
        padding: 1em;
        .hljs-name {
          color: $primaryColor;
          font-weight: 500;
        }
        .hljs-attr {
          color: $useGreen;
          font-weight: 500;
        }
        .hljs-string {
          color: $useRed;
          font-weight: 500;
        }
      }
    }
  }
}

.copyIcon_campnForms {
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  span {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    // margin-right: 10px;
  }
  svg {
    color: #fff;
  }
}
.ant-select-selection-placeholder {
  padding: 0;
  line-height: 38px;
  transition: all 0.3s;
  font-size: small !important;
}
