// variables
$primaryColor: #273df2;
$primaryColorLight: #2133c5;
$seeSeven: #c7c7c7;
$shade1: #e3e3e3;
$fade2: #45505b;
$fade3: #aaaaaa;
$fade4: #8e8e8e;
$fade5: #1c1c1c;
$fade6: #f7f7f7;
$fade7: #858a8f;
$fade8: #bdc1c4;
$descriptionTextColor: #717171;
$brownishColor: #560a0f;
$alertInfo: #f2f9ff;
$radius: 6px;
$titleDeep: #18191f;
$titleDark: #000;
$lightBorder: #f2f2f2;
$lighterBorder: #f7f7f7;
$lightRedBg: rgba(253, 239, 239, 0.4);
$lightBlueBg: #f4f5fe;
$title: #444;
$title2: #383838;
$fade555: #555555;
$paragraph: #999;
$usePurple: #05044e;
$useGreen: #37b34a;
$useYellow: #ffe300;
$useRed: #f45b5b;
$useDeepRed: #ec615b;
$useBlue: #2097f6;
$footerDark: #0b0d17;
$fadeText: #777;
$ease: 0.5s all ease;
$homeShadeBg: rgba(225, 225, 225, 0.1);
