@import 'styles/variables.scss';

.otpbox_outer {
  max-height: 380px;
  text-align: center;
  color: #383838;

  .otpbox {
    margin-top: 50px;
    padding: 20px;
  }
  .otpbox__header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 50px;
  }
  .otpbox__header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 22px;
  }
  .otpbox_cancel_button {
    margin-top: 50px;
    font-weight: 600;
  }
  .birthday__inner_wrapper {
    .ant-picker {
      width: 100%;
      height: 40px;
    }
  }
}
