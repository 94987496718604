.Container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.InnerContainer{
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;  
}
.InnerContainer>p{
    font-family: "Paralucent-medium";
    font-size: 1.75rem;
    font-weight: 600;
}

.FirstBox{
    background-color: #F1F5F5;
    padding: 2rem;
    height:25vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.FirstBox>img{
    width: 100px;
    height: 100px;
}

/* size 16 screen */
@media screen and (min-width: 1990px) {
    .InnerContainer>p {
        font-size: 3rem;
    }

    .FirstBox>img {
        width: 200px;
        height: 200px;
    }

    .InnerContainer{
        height: 60%;
    }
    .InnerContainer>div:last-child>p{
        font-size: 1.5rem !important;
    }

    .FirstBox{
        margin-bottom: 4rem;
        height: 20vh;
    }
}

/* ipad pro screen */
@media screen and (max-width: 1068px) {
    .InnerContainer>p {
        font-size: 1.25rem;
    }
}

/* ipad screen */
@media screen and (max-width: 768px) {
    .InnerContainer>p {
        font-size: 1rem;
    }
    .InnerContainer>div:last-child>p {
        font-size: .85rem !important;
    }
}

/* mobile view */
@media screen and (max-width: 468px) {
    .InnerContainer>p {
        font-size: .95rem;
        text-align: center;
    }

    .InnerContainer>div:last-child>p {
        font-size: .75rem !important;
        text-align: center;
    }
    .FirstBox>img {
        width: 50px;
        height: 50px;
    }
    .InnerContainer{
        width: 70%;   
    }
    .FirstBox{
        padding: 1rem;
    }
}