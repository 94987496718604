@import 'styles/variables.scss';

.topUpWrapper {
  color: #000000;

  .ant-modal-content {
    padding: 30px 40px;
    border-radius: 10px;
    .ant-modal-body {
      padding: 0px;
    }

    #btn--type-primary_lg.topup-button{
      width: 100%;
    }
  }

  .body {
    display: flex;
  }
  .packageWrapper {
    flex: 1;
    max-width: 450px;
    border-right-width: 0.5px;
    border-right-color: #f2f2f2;
    padding-right: 20px;
    h5 {
      font-weight: 600;
      font-size: 20px;
      padding-bottom: 15px;
    }

    .sp {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .topup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #c7c7c7;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 12px 25px 12px 20px;
      cursor: pointer;
      .slotsWrapper {
        display: flex;
        align-items: center;

        .slots {
          font-weight: 500;
          font-size: 20px;
          letter-spacing: -0.055em;
        }

        .credit {
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0;
          margin-left: 5px;
          margin-top: 0;
        }
      }
      .topupPrice {
        display: flex;
        align-items: center;
        p {
          font-weight: 500;
          font-size: 18px;
          color: #717171;
          margin-right: 10px;
          letter-spacing: -0.055em;
        }

        svg {
          width: 17px;
          height: 17px;
          display: none;
          margin-right: -15px;
        }
      }
    }
    .microcopy {
      font-size: 12px;
      font-weight: 500;
      span {
        color: #273df2;
      }
    }
  }

  .formWrapper {
    flex: 1;
    max-width: 450px;
    border-left-width: 0.5px;
    border-left-color: #f2f2f2;
    padding-left: 20px;
    margin-bottom: 0px;
    p {
      margin: 0;
    }
    h2 {
      margin-top: 10px;
      color: #383838;
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 18px;
    }
    h6 {
      font-weight: 600;
      font-size: 14px;
      color: #383838;
      margin-bottom: 20px;
    }
    .card_status {
      display: none;
    }
    .savedCardsWrapper {
      .emptyState {
        border: 2px solid #c7c7c7;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 30px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }

      .selected_card__border {
        border: 2px solid #273df2 !important;
      }
      .cardWrapperDisabled {
        cursor: not-allowed;
      }
      .cardWrapper {
        display: flex;
        background-color: #fbfbfb;
        border: 2px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #383838;
        position: relative;
        cursor: pointer;
        padding: 10px 0;

        .innerDiv {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: none;
          svg {
            margin-right: 10px;
            margin-top: 8px;
            height: 15px;
            width: 15px;
          }
        }

        .select_card {
          display: flex;
          justify-content: flex-end;
        }
        .cardSvgWrapper {
          flex: 0.3;
          svg {
            height: 30px;
            width: 40px;
          }
        }
        .cardType {
          font-weight: 500;
          text-transform: capitalize;
        }
        .cardStatus {
          margin-right: 10px;
        }
        .edit {
          color: #273df2;
        }
        .cardStatusEditWrapper {
          display: flex;
          align-items: center;
          margin-top: 10px;
        }
        .cardOuterWrapper {
          margin-left: 10px;
        }
      }
    }
    .accordion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
      margin-bottom: 30px;
      cursor: pointer;
      h6 {
        margin-bottom: 0px;
      }

      .chevronFaceUp {
        transform: none;
      }
      .chevronFaceDown {
        transform: rotate(180deg);
      }
    }
    .billingInfo {
      font-weight: 600;
      font-size: 16px;
      color: #8e8e8e;
      margin-bottom: 20px;
    }
  }
  .selected_plan__border {
    border: 2px solid #273df2 !important;
    svg {
      display: block !important;
    }
  }
  .checkoutFormContainer {
    // Default Styling
    form {
      .inputWrapper {
        .inputEl,
        input {
          height: 48px;
        }
      }

      .checkboxWrapper {
        margin-bottom: 20px;
      }
    }

    .payment__button {
      margin-bottom: 20px;
    }
  }
  // *****************************************
  // GLOBAL MEDIA QUERY
  // *****************************************

  // *****************************************
  // Max Width - 800px
  // *****************************************
  @media (max-width: 800px) {
    .body {
      flex-direction: column;
    }
    .packageWrapper {
      border: none;
      margin-bottom: 40px;
    }
    .formWrapper {
      border: none;
      h2 {
        line-height: 10px;
        margin-bottom: 0;
      }
    }
  }

  // *****************************************
  // Max Width - 425px
  // *****************************************
  @media (max-width: 425px) {
    .formWrapper {
      padding-left: 0;
    }

    .ant-modal-content {
      padding: 30px 20px 0px;
    }
    .cardSvgWrapper {
      // flex: 0.3 1 !important;
      margin-left: 5px !important;
    }
    .cardOuterWrapper {
      margin-left: 0 !important;
      margin-right: 10px !important;

      // svg {
      //   height: 30px !important;
      //   width: 40px !important;
      // }
    }
  }
}
