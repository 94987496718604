@import './variables.scss';

:root {
  --sideBarWidth: 78px;
  --sideBarWidthActive: 200px;
}

.contactPageContainer {
  position: relative;
  min-height: 100vh;
  width: 100%;

  .ant-input {
    font-family: 'Paralucent-light';
  }

  //   overflow: hidden;

  .sidebar {
    .padLR {
      padding: 0px 24px;
    }

    .padT {
      padding-top: 20px;
    }

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    border-right: 1px solid #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--sideBarWidth);
    background: var(--black-color);
    // padding: 20px 14px 20px 14px;
    padding: 0px;
    z-index: 99;
    transition: all 0.5s ease;

    .addGroup {
      margin-top: 0px;
      opacity: 0;

      p {
        font-size: 18px;
        font-weight: 700;
        color: $fade555;
      }
    }

    .addContact,
    .addGroup img {
      background: rgba(32, 151, 246, 0.04);
      border-radius: 5px;
      height: 25px;
      width: 25px;
    }

    .contactListMenu ul li {
      padding: 10px 20px;
    }

    &.active {
      @media (min-width: 700px) {
        width: var(--sideBarWidthActive);

        &~.contentContainer {
          width: calc(100% - var(--sideBarWidthActive));
          left: var(--sideBarWidthActive);
        }

        .addGroup {
          opacity: 1;
        }

        .addContact {
          opacity: 0;
        }

        & ul {
          li {
            margin-bottom: 0;

            .listMenuContent {
              transition: 0s;
              // display: block;
              pointer-events: auto;
            }

            .mobileMenuItem {
              // opacity: 0;
              display: none;
            }
          }
        }
      }
    }

    & ul {
      margin-top: 40px;

      li {
        position: relative;
        // height: 50px;
        margin-bottom: 0;
        width: 100%;
        list-style: none;

        .listMenuContent {
          display: none;
          pointer-events: none;
          transition: all 0.3s ease;

          .contact_title {
            color: #1c1c1c;
          }

          .total_contacts {
            color: #717171;
          }

          p {
            font-size: 16px;
            font-weight: 600;
            color: $fade555;
          }

          .faded {
            color: $fade3;
            font-weight: 500;
            font-size: 14px;
          }
        }

        .mobileMenuItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .linkName {
            background-color: #f3f4fc;
            // padding: 5px;
            text-align: center;
            width: 35px;
            height: 35px;
            border-radius: 10px;
            color: $primaryColor;
            font-weight: 700;
          }
        }
      }
    }
  }

  .contentContainer {
    height: 100%;
    margin-left: auto;
    left: var(--sideBarWidth);
    padding: 20px;
    padding-top: 0;
    transition: all 0.5s ease;

    .heading {
      margin-bottom: 10px;

      h3 {
        font-size: 18px;
        color: $fade555;
        font-weight: 700;
        margin-bottom: 8px;
      }

      .contentEditable {
        font-size: 14px;
        color: $fade3;
        font-weight: 500;
      }
    }

    .actionTab {
      .searchInput {
        background-color: #f7f7f7;
        border-radius: 4px;
        color: #aaaaaa;

        & * {
          background-color: $lighterBorder;
        }
      }

      .actions {
        button {
          min-width: 130px;
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;

          &:hover {
            color: #fff;
          }
        }

        .ghostBtn {
          border: 1px solid $shade1;

          &:hover {
            color: #000;
          }
        }
      }

      @media (max-width: 970px) {
        .flex-space-btw {
          display: block;

          .actions {
            margin-top: 20px;

            @media (max-width: 650px) {
              .ant-space {
                display: block;

                .ant-btn {
                  min-width: 80%;
                  margin: auto;
                  margin-bottom: 10px;

                  @media (max-width: 450px) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .contactTableContainer {
      border: none !important;
      padding: 0 !important;
      font-family: 'Paralucent-Light';

      table {
        .ant-table-thead {
          th {
            color: #717171;
            font-size: 16px;
            font-weight: 400;
            padding: 6px;
          }
        }

        td {
          color: #717171;
          font-size: 16px;
          font-weight: 400;
          border-bottom: 0 !important;

          .tableActionBtns {
            & button {
              border: 2px solid $lightBorder;
              color: $fade4;
              font-size: 12px;
              border-radius: 4px;

              &:first-child {
                margin-right: 15px;
              }

              &:last-child {
                color: #f45b5b;
              }

              &.allcontact-edit_btn {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }

  .contact_head_box {
    height: 185px;
    background: rgba(12, 127, 218, 0.05);
    border-radius: 8px;

    h1 {
      font-size: 32px;
      line-height: 44px;
      color: #45505b;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: #555555;
    }

    .link {
      color: #273df2;
      text-decoration: underline;
    }

    .search-input {
      margin-top: 20px;
      border: none;
      width: 100%;
      max-width: 301px;
      height: 35px;
      background: #ffffff;
      border-radius: 4px;
      padding: 8px 15px;
      gap: 11px;

      .anticon {
        color: #8e8e8e;
        width: 13px;
        height: 13px;
      }
    }
  }

  .contact_select {
    border-radius: 4px;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #717171;
    flex-grow: 1;
  }

  .contact_group_btn {
    background: #717171;
    border-radius: 4px;
    padding: 8px 26px;
    gap: 5px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #f7f7f7;

    .anticon svg {
      color: #f7f7f7;
      margin-top: 2px;
      width: 16px;
      height: 16px;
    }
  }

  .contact_group_btn:hover {
    color: #f7f7f7;
  }

  .contact_group_btn:focus {
    color: #f7f7f7;
  }

  .contact_actions {
    .export_btn {
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      align-items: center;
      padding: 8px 14px;
      gap: 5px;
    }

    .export {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #8e8e8e;
    }
  }

  .addNew_btn {
    background: #273df2;
    border-radius: 4px;
    letter-spacing: 0.01em;
    color: #f7f7f7;
    padding: 8px 26px;
    gap: 5px;
  }

  .mobileMenuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .linkName {
      background-color: #f3f4fc;
      text-align: center;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      color: $primaryColor;
      font-weight: 700;
    }
  }

  .contactListMenu ul li {
    padding: 10px 20px;
  }

  h3 {
    line-height: revert !important;
  }
}

// New Contact Page

.newContentContainer {
  height: 100%;
  width: 100%;
  margin-left: auto;
  padding: 0 20px 20px;
  transition: all 0.5s ease;
  position: relative;

  :global {
    .heading {
      margin-bottom: 10px;

      h3 {
        font-size: 18px;
        color: $fade555;
        font-weight: 700;
        margin-bottom: 8px;
      }

      .contentEditable {
        font-size: 14px;
        color: $fade3;
        font-weight: 500;
      }
    }

    .active-opt {
      color: #FBFBFB;
    }

    .actionTab {
      margin: 0rem 0 24px 0;

      .searchInput {
        background-color: #f7f7f7;
        border-radius: 4px;
        color: #aaaaaa;

        & * {
          background-color: $lighterBorder;
        }
      }

      .actions {
        button {
          min-width: 100px;
          min-height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;

          &:hover {
            color: #fff;
          }
        }

        .ghostBtn {
          border: 1px solid $shade1;

          &:hover {
            color: #000;
          }
        }
      }

      @media (max-width: 970px) {
        .flex-space-btw {
          display: block;

          .actions {
            margin-top: 20px;

            @media (max-width: 650px) {
              .ant-space {
                display: block;

                .ant-btn {
                  min-width: 80%;
                  margin: auto;
                  margin-bottom: 10px;

                  @media (max-width: 450px) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .circle {
      font-size: 12px;
      font-family: 'Paralucent-light';
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0;
      margin-top: -5px;
    }

    .contactTableContainer {
      border: 0 !important;
      margin: 0 !important;
      padding: 0px 0 !important;
      font-family: 'Paralucent-light';

      .ant-table-header {
        border-radius: 0 !important;
      }

      table {
        font-family: 'Paralucent-light';
        border: '1px solid #e5e5e5';

        .ant-table-thead {
          background-color: #e3e3e3;
        }

        th {
          color: #717171;
          font-size: 16px;
          font-weight: 400;
          padding: 6px 10px;
          background-color: #e3e3e3;
          font-family: 'Paralucent-light';

          &:before {
            height: 0 !important;
          }
        }

        td {
          color: #717171;
          font-size: 16px;
          font-weight: 400;
          padding: 16px 10px;
          border-bottom: 0;
          font-family: 'Paralucent-light';
          cursor: pointer;

          .mdTableActionBtns {
            & button {
              border: 2px solid $lightBorder;
              color: $fade4;
              font-size: 12px;
              border-radius: 0px;

              &:first-child {
                margin-right: 15px;
              }

              &:last-child {
                color: #f45b5b;
              }

              &.allcontact-edit_btn {
                color: $primaryColor;
              }
            }
          }
        }
      }

      .ant-checkbox-inner {
        border-radius: 0;
        // background-color: #fbfbfb;
        background-color: white;

        &::after {
          border: 2px solid #273df2;
          border-top: 0;
          border-inline-start: 0;
        }
      }

      .option-pop-up {
        background-color: #fff;
        padding: 16px 18px;
        flex-direction: row;
        align-items: center;
        box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 1px solid #e3e3e3;
        display: flex;
        justify-content: center;
        font-family: 'Paralucent-light';
        width: fit-content;
        margin: 0 auto;
      }

      .divider {
        height: 34px;
        width: 1px;
        background-color: #c7c7c7;
        margin: 0 1rem;
      }
    }

    .contact_select {
      border-radius: 0px;
      align-items: center;
      color: #717171 !important;
      width: 348px;
      font-family: 'Paralucent-light';
    }

    .ant-select-selector {
      font-family: 'Paralucent-light';
      height: 34px !important;
    }


    .addGrpBtn {
      height: 34px;
      background: transparent;
      border-radius: 4px;
      max-width: 200px;
      padding: 8px 25px;
      font-size: 14px;
      color: #273df2 !important;
      border: 1px solid #273df2;

      .addGroupText {
        color: #273df2 !important;
        letter-spacing: 0.02em !important;
      }
    }

    .addGrpBtn.solid {
      background: #273df2;
      color: white !important;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
      border-radius: 0;
    }

    .page-next {
      display: flex;
      height: 40px;
      padding: 8px 14px;
      justify-content: center;
      align-items: center;
      border: 1px solid #cfd9e4;
      border-radius: 8px;
      background-color: #f8f8f9;
      color: #737a91;
      gap: 0.5rem;

      p {
        font-family: 'Paralucent-medium';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .ant-pagination-item {
      display: flex !important;
      height: 40px !important;
      justify-content: center;
      align-items: center;
      border-radius: 8px !important;
      min-width: 38px !important;
      margin-inline-end: 0 !important;
      font-family: 'Paralucent-medium';
      font-size: 16px;
      font-weight: 400;

      a {
        color: #737a91 !important;
        font-family: 'Paralucent-medium';
      }
    }

    .ant-pagination-item-active {
      background-color: #273df2 !important;

      a {
        color: white !important;
      }
    }

    .ant-pagination-total-text {
      order: 1;
      color: #737a91;
      font-family: 'Paralucent-medium';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      display: flex !important;
      height: 40px !important;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
    }

    .ant-table-body {
      height: 400px !important;
    }

    .ant-table {
      border: 1px solid #e3e3e3;
      border-radius: 0 !important;
    }

    .ant-select-selector {
      border-radius: 4px !important;
      font-family: 'Paralucent-light';
      font-size: 14px;
    }

    .contactHeaderCont {
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      background: rgba(12, 127, 218, 0.05);
      margin: 24px 20px 40px;
      padding: 1.6rem 2.25rem 0rem;
      height: 178px;
      overflow: hidden;

      .contactTextPart {
        .contHTitle {
          padding-bottom: 4px;
          color: #45505b;
        }

        .contHDesc {
          // max-width: 478px;
          color: #555555;
          padding-bottom: 1.2rem;
        }

        .contHDescLink {
          color: #273df2;
          padding-bottom: 1.2rem;
          margin-top: 0rem;
        }

        .contactSearchInput {
          max-width: 301px;
          border: 1px solid #f2f2f2;
          // height: 35px;
          border-radius: 4px;
          background-color: white;
        }
      }

      .contactImgPart {
        .contHImg {
          padding-right: 1rem;
        }
      }
    }

    .contactWrapper {
      margin: 0 1.3rem;
    }
  }
}

/* Start contact header component*/

.delete-text {
  color: rgb(236, 97, 91) !important;
  cursor: pointer;
  text-align: center;
  padding: 2rem 0 1rem;
  border-top: 1px solid #e5e5e5;
  margin-top: 24px;
}

.contact-btn {
  border-radius: 4px !important;
  background-color: #273df2;
  font-size: 14px !important;
  color: white;
  // margin-top: 40px;
  font-family: 'Paralucent-light';
  padding: 8px 26px !important;
}

.modalButton {
  width: 321px;
  padding: 8px 26px;
  background-color: #273df2;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: 'Paralucent-Medium';
}

.instant-mail-btn {
  border-radius: 4px !important;
  background-color: #273df2;
  font-size: 14px !important;
  color: white;
  // margin-top: 40px;
  font-family: 'Paralucent-light';
  padding: 8px 26px !important;
  width: 271px;
}

.instant-mail-alert {
  color: #1677FF !important;
  font-size: 12px;
  font-family: Paralucent-Light;
  border-radius: 16px !important;
  background-color: #D0E4FF !important;
  padding: 8px 6px;
  display: flex;
  align-items: center;
}

.info-text {
  color: #717171;
  font-family: Paralucent-Light;
  font-size: 14px;
}

.info-item {
  color: #1c1c1c;
  font-family: Paralucent-Light;
  font-size: 14px;
}

.addNewContactModal {
  :global {
    .ant-select-dropdown {
      font-family: 'Paralucent-Light';
    }

    .ant-select-selection-item {
      .ant-select-selection-item-content {

        font-family: 'Paralucent-Light';
      }
    }

    .ant-form-item-label {
      font-size: 14px;
      padding: 0 !important;

      label {
        font-family: 'Paralucent-light' !important;
        color: #555 !important;
        font-weight: 400 !important;
      }
    }

    .ant-form-item-required {
      &:before {
        display: none !important;
      }
    }

    .ant-alert-info {
      background-color: #d0e4ff !important;
      border: none !important;
      border-radius: 0 !important;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      font-family: 'Paralucent-light';
    }

    .ant-input {
      border-radius: 4px;
      font-family: 'Paralucent-light';
      font-size: 14px;
      color: #717171;
    }

    .ant-select-selection-item {
      padding: 0 10px;
    }

    .ant-input-prefix {
      color: #8e8e8e;
    }

    .ant-form-item-control-input {
      border-radius: 4px;

      .ant-input-affix-wrapper {
        border-radius: 4px;
      }
    }

    .ant-form-item-row {
      gap: 10px;
    }

    .contactDrawer {
      .close-icon {
        background-color: #e3e3e3;
        width: 36px;
        height: 36px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .creator-name {
        font-family: 'Paralucent-light';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
      }

      .name-tag {
        padding: 4px 10px;
        border-radius: 12px;
        background-color: #f2f2f2;
        font-size: 12px;
        font-family: 'Paralucent-light';
        width: fit-content;
        color: #717171;
      }

      .name-box {
        width: 62px;
        height: 62px;
        background-color: #00a3ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        font-family: 'Paralucent-light';
      }

      .creator {
        padding: 17px 0px;
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        margin-top: 10px;
      }
    }
  }
}

.contact-page {
  margin: 39px 0 20px !important;
}

.empty-img {
  width: 167px;
}

.empty-text {
  color: #8e8e8e;
  font-family: Paralucent-Light;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.empty-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 350px;
  justify-content: center;
}

.selection-pop {
  height: 66px;
}

.deleteModal {
  width: 25% !important;
}

.delete-contact-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.del-icon {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  height: 56px;
  width: 60px;
}

.delete-title {
  color: #000;
  text-align: center;
  font-family: 'Paralucent-Light';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143.301%;
  margin-bottom: 8px;
  width: 73%;
}

.tempModal {
  width: 35% !important;
  padding: 2rem 3.5rem;
}

.template-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 4rem 0rem 1rem;
}

.template-title {
  color: #000;
  text-align: center;
  font-family: 'Paralucent-Medium';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  // width: 73%;
}

.template-text {
  color: #717171;
  text-align: center;
  font-family: 'Paralucent-Light';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px !important;
  letter-spacing: 0.42px;
}

.template-btn {
  width: 100%;
  background-color: #273df2;
  color: white !important;
  height: 34px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  font-family: 'Paralucent-Medium';
}

.template-btn.template-btn-loading{
  background-color: #273df2 !important;
  opacity: 0.5;
  // color: #717171 !important;
  border: 1px solid #c7c7c7 !important;
}

.sub-text {
  color: #717171;
  text-align: center;
  font-family: 'Paralucent-Light';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
  letter-spacing: 0.36px;
}

.delete-btn {
  width: 100%;
  background-color: #ec615b;
  color: white !important;
  height: 34px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  font-family: 'Paralucent-Light';
}

.outline-btn {
  width: 100%;
  background-color: transparent;
  color: #717171 !important;
  height: 34px !important;
  font-size: 14px !important;
  border: 1px solid #c7c7c7 !important;
  border-radius: 4px !important;
  font-family: 'Paralucent-Light';
}

.import-btn {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fbfbfb;
  border-radius: 4px !important;
  font-family: 'Paralucent-Medium';
  font-size: 14px !important;
  font-style: normal;
  color: #273df2;
  border: 0 !important;
  padding: 8px 26px !important;
}

.info-box {
  padding: 11px 16px;
  width: 100%;
  border-radius: 8px;
  background: #f5f5f5;
  color: #383838;
  font-family: 'Paralucent-Light';
  font-size: 12px;
  margin-bottom: 38px;
  margin-top: 4px;
}

.contact-modal {
  width: 32% !important;

  @media (max-width: 760px) {
    width: 90% !important;
  }
}

.delete-modal {
  width: 23% !important;

  @media (max-width: 760px) {
    width: 90% !important;
  }
}