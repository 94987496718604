@import './variables.scss';

.instantEmail-reporting {
  width: 100%;
  .instantEmail-subject-full {
    .instantEmail-subject {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;
      width: 60%;
      .instantEmail-header {
        color: #8e8e8e;
      }
      .instantEmail-info {
        color: #383838;
        margin-right: 1rem;
      }
    }
    .instantEmail-details {
      display: flex;
      padding: 0px;
      gap: 74px;
      width: 100%;
      .instantEmail-header {
        color: #8e8e8e;
      }
      .instantEmail-info {
        color: #383838;
        margin-right: 1rem;
      }
      @media (max-width: 460px) {
        padding-top: 10px;
      }
    }
    @media (max-width: 460px) {
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 460px) {
    width: 100%;
    max-width: 460px;
  }

  .instantEmail-chart {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 18px;
    width: 100%;
    .instantEmailChart-info {
      border: 1px solid #f2f2f2;
      padding: 10px;
      width: 100%;
      max-width: 162px;
      color: #717171;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      .instantEmailChart-percent {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
      }
      .instantEmail-rate {
        color: #717171;
      }
      @media (max-width: 460px) {
      }
    }
    @media (max-width: 460px) {
      display: grid;
      grid-template-columns: 2fr 2fr;
      gap: 10px;
    }
    .green {
      color: #2eb67d;
    }
    .orange {
      color: #f79e1b;
    }
    .purple {
      color: #1976d2;
    }
    .black {
      color: #191058;
    }
    .brown {
      color: #af5700;
    }
    .red {
      color: #ec615b;
    }
  }

  // Email Table
  .deliveredStyle {
    width: 33.65px;
    height: 23px;
    background: #f5fbf6 !important;
    font-family: 'Inter';
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    .delivered {
      color: #37b34a !important;
    }
    .not-delivered {
      color: #8e8e8e !important;
    }
  }
  .opens {
    color: #2eb67d !important;
  }
  .clicks {
    color: #f79e1b !important;
  }
  .ant-table-wrapper {
    border: 0.5px solid #f2f2f2 !important;
    border-radius: 4px 4px 0px 0px;
    width: 100%;
    @media (max-width: 460px) {
      max-width: 460px;
      overflow-x: scroll;
    }
  }
  .ant-table-thead > tr > th {
    background: #fff !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: #717171 !important;
    font-family: 'Inter' !important;
  }
  .ant-table-tbody > tr > td {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #555555 !important;
    font-family: 'Inter' !important;
  }
}
