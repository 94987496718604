@import 'variables.scss';

.email-header {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #f9f5fe;
  height: 178px;
  margin: 20px 20px 30px 31px;
  padding: 1.6rem 2.25rem 0rem;
}

.modal-no-padd {
  .ant-modal-body {
    padding: 0 !important;
  }
}
.modal-no-padd2 {
  width: 25% !important;
  .ant-modal-body {
    padding: 0 !important;
  }
}

.textPart {
  .campHTitle {
    padding-bottom: 4px;
    color: #45505b;
  }

  .campHDesc {
    max-width: 478px;
    color: #555555;
    padding-bottom: 1.2rem;
  }

  .campHeaderInput {
    border: none;
  }
}

.imgPart {
  height: 100%;

  img {
    height: 100%;
  }
}

.action-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.action-hero-title {
  width: 70%;
  max-width: 793px;
  padding: 24px 0;
  display: flex;
  align-items: center;
  gap: 14px;

  input {
    max-width: 297px;
  }

  .back-arrow {
    cursor: pointer;
    padding: 7px;
    border-radius: 4px;
    background: #fbfbfb;
  }
}

.action-header {
  width: 70%;
  max-width: 793px;

  .ant-collapse {
    border-radius: unset;
    border: 1px solid #d9d9d9;

    .typography--variant-h6Light {
      font-family: 'Paralucent-Medium';
      font-size: 18px;
      color: $title2;
    }

    .typography--variant-smallTextRegular {
      font-family: 'Paralucent-Light';
      color: $descriptionTextColor;
    }

    input {
      font-family: 'Paralucent-Light';
      font-size: 14px;
      color: $fade4;
      border-radius: 4px;
      padding: 12px 14px;
    }

    & .ant-collapse-header:last-child {
      border-radius: unset;
      border-bottom: none;
    }

    .ant-collapse-header {
      padding: 24px;
      border-bottom: 1px solid #fbfbfb;
    }

    .ant-collapse-item {
      border-bottom: none;
    }

    .ant-collapse-item:nth-child(2),
    .ant-collapse-item:nth-child(3),
    .ant-collapse-item:last-child {
      border-top: 1px solid #e3e3e3;
    }

    .ant-collapse-content,
    .ant-collapse-content-active {
      border-top: none;
    }

    .ant-collapse-content-box {
      padding: 16px 24px;

      label {
        height: unset;
      }
    }
  }
}

 .paid_tag {
   font-size: 10px;
   background-color: $lightBorder;
   padding: 1px 10px;
   // font-weight: 500;
   color: $fade4;
   border-radius: 2px;
 }

 .gray-color{
  color: #1c1c1c;
  font-family:'Paralucent-Light';
  font-weight: 400;
 }

.action-button-wrapper {
  width: 70%;
  max-width: 793px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.action-button-wrapper .save-to-drafts {
  margin-top: 2rem;

  .drafts {
    text-decoration: underline;
  }
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 10px;
}

.action-button .schedule {
  border: 1px solid rgb(241, 231, 231);
  height: 34px;
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.14px;
  }
}

.form_container .button {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.setting-mobile {
  width: 100%;
}

.instant-email-form-container {
  width: 100%;
  display: flex;
}

.instant-email-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;

  input {
    max-width: 320px;
  }
}

.instant-email-form-button button {
  background-color: $primaryColor;
  color: #fff;
  margin-top: 0.5rem;
}

.instant-email-form-header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
}

.instant-email-create-group {
  color: #273df2;
  margin-left: 24px;
  text-decoration: underline;
  font-weight: 400;
  font-family: 'Paralucent-Medium';
  font-size: 14px;
  cursor: pointer;
  padding-top: 2px;
}

.instant-email-upload-contact {
  font-weight: 400;
  font-family: 'Paralucent-Light';
  font-size: 14px;
  color: #1c1c1c;
}

.instant-email-form-header .form-group {
  width: 300px;
  margin-bottom: 20px;
}

.instant-email-form-button-header button {
  background-color: $primaryColor;
  color: #fff;
  margin-top: 32px;
}

.upload-contact .or {
  padding-top: 0.5rem;
}

.instant-email-form-link {
  margin-left: 7px;
  color: #273df2 !important;
  text-decoration: underline;
  text-decoration-color: #273df2;
  cursor: pointer;
}

.instant-email-date {
  color: #aaaaaa;
}

.detailed_report_button #btn--type-grey_default {
  width: 150px;
  padding: 8px 13px;
}

.button-text {
  color: white !important;
}

.mdtab-view_selector__list .folder {
  color: #273df2;
  padding-left: 3px;
}

.table-data {
  color: #aaaaaa;
  margin-top: 0.6rem;

  line-height: 2;
}

.table-status {
  color: #ffc107 !important;
  background: rgba(255, 193, 7, 0.1);
  padding: 2px 8px 4px 8px;
  border-radius: 12px;
  height: 25px;
}

.table-status-draft {
  color: #8e8e8e !important;
  background-color: #f7f5f5 !important;
  padding: 2px 6px 4px 6px;
  border-radius: 12px;
  width: 46px;
  height: 25px;
}

.table-status-queued {
  color: #ffc107 !important;
  background-color: #f7f5f5a8 !important;
  padding: 2px 6px 4px 6px;
  border-radius: 12px;
  text-align: center;
  height: 25px;
}

.table-status-processing {
  color: #37b34a !important;
  background-color: #f7f5f5 !important;
  padding: 2px 8px 4px 8px;
  border-radius: 12px;
  height: 25px;
}

.table-status-failed {
  color: #ff0000 !important;
  background-color: #f7f5f5 !important;
  padding: 2px 8px 4px 8px;
  border-radius: 12px;
  height: 25px;
}

.table-status-completed {
  color: #37b34a !important;
  background-color: #98dda3 !important;
  padding: 2px 8px 4px 8px;
  border-radius: 12px;
  height: 25px;
  // cursor: not-allowed !important;
}

// .disabled-row {
// pointer-events: none;
// opacity: 0.5;
// cursor: not-allowed !important;
/* Adjust the opacity to visually indicate the row is disabled */
// }

.table-mode {
  color: #aaaaaa !important;
}

.table-mode-per {
  margin-top: 0.6rem;
}

.ant-input-affix-wrapper {
  border: none;
}

.createEmailModals {
  display: flex;
  justify-content: space-between;
  border: none;
  margin-top: 10px;
  column-gap: 34px;

  .email-builder {
    & > p {
      text-align: center;
    }
  }

  .editor-heading,
  .news-heading {
    margin: 1rem auto 0.5px;
  }
}

.createEmailModalWrapper {
  & .ant-modal {
    width: fit-content !important;
  }

  .ant-modal-content {
    padding: 34px 31px 31px;

    & .ant-modal-body {
      padding: 0;
    }
  }
}

.paragraph {
  width: 162px;
}

.icon-edit {
  cursor: pointer;
}

.icon-delete {
  cursor: pointer;
  margin-left: 4px;
  position: relative;
  right: 3rem;
}

.modal-header {
  padding-bottom: 1.5rem;
  text-align: center;
}

.top {
  padding-top: 0.2rem;
}

.select-button .global {
  border: 1px solid rgb(241, 231, 231);
  text-align: left;
  margin-top: 1rem;

  .select-text {
    color: #717171;
  }
}

.instant-email-table {
  width: 100%;
  padding: 0 20px 30px 31px;
  height: 100%;

  & > div.ml-5 {
    margin-left: 0;
  }

  div.ant-table-body {
    overflow: unset;
    max-height: fit-content;
  }
}

.calendar-footer .timePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.instantEmailActionIcons {
  display: flex;
  margin-right: 1rem;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none !important;
}

.custom-picker {
  visibility: hidden;
}

.ant-picker-dropdown .ant-picker-datetime-panel {
  display: flex;
  flex-direction: column;
}

.ant-picker-footer{
  display: flex;
}
.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
}

a.ant-picker-now-btn {
  font-size: 0;
}

.ant-btn.ant-btn-sm {
  height: 34px;
  width: 143px;
  padding: 2px 7px 0px;
  background: #273df2;
  border-radius: 4px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #f7f7f7;
  font-family: 'Paralucent-Medium';
}

.ant-picker-ok > button.ant-btn > span {
  font-size: 0;
}

.ant-picker-ok > button.ant-btn > span:after {
  content: 'Schedule now';
  font-size: 14px;
  /* original font size */
}

.preview-instantEmail-modal {
  .ant-modal-content {
    height: 578px;

    .ant-modal-body {
      padding: 0;
      height: 100%;
    }
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    font-size: 16px;
    font-family: 'Inter';

    .color-dark {
      color: $title2;
      font-weight: 500;
    }

    .color-light {
      color: $fade3;
      font-weight: 500;
    }

    .preview-header {
      margin-top: 20px;

      .info {
        display: flex;
        gap: 10px;

        & > img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        & > div {
          p {
            margin: 0;
          }
        }
      }

      .subject {
        margin-top: 10px;

        & > p {
          margin: 0;
        }
      }
    }

    .preview-body {
      max-height: 300px;
      overflow: auto;
      margin: 20px 0;
      font-weight: 300;

      &::-webkit-scrollbar-thumb {
        background-color: #b3b3b3;
      }
    }

    .preview-footer {
      display: flex;
      gap: 8px;
      position: absolute;
      bottom: 10px;
      color: $titleDark;
      font-weight: 400;
    }
  }
}

.fromandreply-panel {
  position: relative;
}

.prototype-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;

  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  transition: all 0.5s ease-in-out;

  .prototype {
    width: 341px;
    height: 245px;
    background-color: #fbfbfb;
    border-radius: 24px 24px 0px 0px;
    border: 1px solid $shade1;
    border-bottom: none;
    color: #1c2127;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 13px;

      .time {
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        margin-left: 4px;
      }

      .cellular-wifi-battery {
        display: flex;
        gap: 4.5px;
      }
    }

    .content {
      padding: 12px 16px;

      .content-header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 16px;

        .back-arrow {
          position: absolute;
          left: 0;
        }

        .inbox {
          font-size: 18px;
          font-weight: 400;
        }
      }

      .content-body {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        .info {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;

          .grey-circle {
            min-width: 37px;
            height: 37px;
            border-radius: 50%;
            background-color: $fade3;
          }

          .email-info {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .left {
              display: flex;
              flex-direction: column;

              .email-sender-name {
                font-size: 16px;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .email-subject {
                font-size: 14px;
                margin-top: 0;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .top {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .one {
                  width: 143px;
                  height: 14px;
                  border-radius: 24px;
                  background: #d9d9d9;
                }

                .two {
                  width: 129px;
                  height: 7px;
                  border-radius: 24px;
                  background: #d9d9d9;
                }
              }

              .bottom {
                margin-top: 22px;
                width: 166px;
                height: 7px;
                border-radius: 24px;
                background: #d9d9d9;
              }
            }

            .right {
              .time {
                font-size: 16px;
              }

              .dummy-time {
                width: 52px;
                height: 14px;
                border-radius: 24px;
                background: #d9d9d9;
              }
            }
          }
        }
      }
    }
  }
}

.email-subject-panel {
  position: relative;

  .email-subject-panel-content {
    display: flex;
    width: 100%;

    .subject-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      width: 45%;
      margin-bottom: 38px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .email-subject {
          max-width: 320px;
          line-height: 1.5;
        }
      }

      .instant-email-button {
        background-color: $primaryColor;
        color: #fff;
        width: fit-content;
      }
    }

    .prototype-container {
      .prototype {
        height: 175px;
        overflow: hidden;
      }
    }
  }
}

.design-email-panel {
  .design-email-button {
    background-color: $primaryColor;
    width: fit-content;
    color: #fff;
    font-size: 14px;
    margin-bottom: 1rem;
  }
}

.button-opacity {
  opacity: 0.5;
  cursor: not-allowed;
}

.createmodaltitle {
  font-family: 'Paralucent-Medium';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #1c1c1c;
}

.single-option {
  display: flex;
  padding: 7px 12px 6.636px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
  border-radius: 8px;
  background: #f4f4f4;
  width: 100%;
  position: relative;

  &:hover {
    border-radius: 8px;
    border: 0.5px solid var(--primary-blue, #273df2);
    background: #f4f4f4;
    box-shadow: 0px 8px 10px 0px rgba(39, 61, 242, 0.15);
    transition: all 0.3s ease-in-out;
  }
}
.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-option-icon {
  width: 40%;
  height: 100%;
  overflow: hidden;
}

.single-option-text {
  width: 60%;

  .editor-head {
    font-family: 'Paralucent-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.14px;
    color: #000;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .editor-body {
    color: #555;
    font-family: 'Paralucent-Light';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
    letter-spacing: 0.48px;
  }
}

.createEmailModals-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

// .createEmailModalWrapper-new .ant-modal{

.email-subject-mention {
  font-family: 'Paralucent-Light';
  font-size: 14px;
  color: #8e8e8e;
  border-radius: 4px;
  padding: 12px 14px;
  max-width: 300px;
  line-height: 1.5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  // box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
}

.input-info {
  font-size: 12px;
  font-family: 'Paralucent-Light';
  color: #8e8e8e;
  padding: 2px 5px;
  margin: -30px 0 24px;
}
.btnPrimary2 {
  width: 100%;
  background-color: #ec615b;
  color: white;
  height: 34px;
  font-size: 14px;
  border-radius: 4px;
  font-family: 'Paralucent-Light';
}
.delet-icon-email {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  height: 56px;
  width: 60px;
}

.sender-email-address-container{
 border: 1px solid #E3E3E3;
 border-radius: 8px;
 background-color: #FBFBFB;
 padding: 20px 16px;
}

.add-sender-email-input{
  font-weight: 400;
  font-size: 14px;
  font-family: "Paralucent-Light";
}

.step-4-textarea-container {
  height: 194px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  box-shadow: 3px 3px 3px #e3e3e3;
  margin-top: 1rem;
  font-family: Paralucent-Light;
  padding: 1rem;
  width: 100%;
}

.step-4-textarea {
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 1rem;
  font-family: Paralucent-Light;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: hidden;
  resize: none;
}
.step-4-textarea:active {
  outline: none;
}

.step-4-p {
  font-family: Paralucent-Light;
  padding: 4px 8px;
  background-color: #f2f2f2;
  border-radius: 8px;
  margin-left: 1rem;
}

.design-email-builder {
  color: #273df2;
  border: 1px solid #273df2 !important;
  background-color: #fff;
  font-size: 14px;
  font-family: Paralucent-Light;
  // margin-left: 1rem;
  margin-top: 1rem;
}

@media screen and (max-width: 1350px) {
  .step-4-textarea-container {
    width: 100%;
  }

  .step-4-textarea {
    width: 100%;
    border: none;
    overflow: hidden;
    resize: none;
  }
}

@media screen and (max-width: 1100px) {
  .step-4-textarea-container {
    width: 100%;
  }

  .step-4-textarea {
    width: 100%;
    border: none;
    overflow: hidden;
    resize: none;
  }
}
