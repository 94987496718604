@import 'styles/variables.scss';

.payGoContainer {
  margin-top: 20px;
  background-color: #fcfcfc;
  padding: 25px 30px 40px 30px;
  border-radius: 8px;  
  .desc {
    p,
    span {
      font-weight: 600;
      font-size: 18px;
    }
    span {
      color: $descriptionTextColor;
      font-size: 14px;
      color: $descriptionTextColor;
    }
  }
  .payInputSection {
    border: 2px solid $seeSeven;
    border-radius: 5px;
    padding: 20px 15px;
    background-color: #fff;

    .__microcopy {
      font-weight: 500;
      font-size: 12px;
      color: $descriptionTextColor;
      margin-bottom: 5px;
    }

    .__desc {
      font-weight: 600;
      p {
        color: $fade5;
        font-size: 14px;
      }
      input {
        border-bottom: 1.5px solid rgba(39, 61, 242, 0.49);
        width: 40%;
        font-size: 20px;
      }
    }
    .payGoPriceDisplay {
      background: rgba(212, 216, 252, 0.2);
      border-radius: 5px;
      height: 70px;
      min-width: 100px;
      h3 {
        font-weight: 600;
        font-size: 24px;
        color: #000;
      }
    }
  }
}

.discount-container {
  padding: 3px;
  background-color: #EBF7ED;
  border-radius: 4px;
  display: flex;
  align-items: center;
  // justify-content: center;
  min-width: 293px;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.discount-text {
  color: #37B34A;
  font-size: 14px;
  font-family: Paralucent-Medium;
} 
 .__microcopy {
    font-weight: 400;
    font-size: 12px;
    color: $descriptionTextColor;
    margin-bottom: 5px;
  }

