@import 'styles/variables.scss';

.paymentDetailsSection {
  .ant-checkbox-inner {
    border-radius: 100px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
  .capsule {
    color: #1c1c1c;
    font-weight: 500;
    border-radius: 4px;
    outline: 1px solid $primaryColor;
    padding: 10px 12px;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    &.selected {
      background-color: #273df20d;
    }
    .capsule_discount {
      color: #fea800;
    }
  }

  .planSelectionContainer {
    margin-top: 50px;
    border: 2px solid $seeSeven;
    border-radius: 5px;
    padding: 12px 10px;
    &.gridItem-2 {
      grid-gap: 0px;
      gap: 0px;
    }
    &.selected {
      border: 2px solid $primaryColor;
    }

    .plan_box {
      cursor: pointer;
      padding: 20px;
      background: #fcfcfc;
      &.selected {
        background: #ffffff;
        box-shadow: 0px 4px 29px #efefef;
      }
      // &:first-child {
      //   // background-color: red;
      //   background: #ffffff;
      //   box-shadow: 0px 4px 29px #efefef;
      // }

      .header {
        margin-bottom: 15px;
      }

      .palnTitle,
      .price h4 {
        font-weight: 600;
      }
      .palnTitle {
        font-size: 14px;
        color: $fade4;
        text-transform: uppercase;
        padding: 2px 6px;
        margin-left: 5px;
        border-radius: 4px;
      }

      .palntitle__odd {
        color: #ffb82e;
        background-color: #ffb82e0d;
      }
      .palntitle__even {
        background-color: #37b34a0d;
        color: #37b34a;
      }

      .amount,
      .planDesc {
        font-size: 24px;
        letter-spacing: -0.055em;
        font-weight: 500;
      }
      .amount {
        color: #000;
      }
      .planDesc {
        color: $descriptionTextColor;
      }
      .price {
        margin-top: 3px;
        h4 {
          font-weight: 500;
          font-size: 16px;
          color: $fade4;
        }
      }
    }
  }
  .comparePlans {
    color: $primaryColor;
  }
  .payGoContainer {
    margin-top: 30px;
    .desc {
      p,
      span {
        font-weight: 600;
        font-size: 18px;
      }
      span {
        color: $descriptionTextColor;
        font-size: 14px;
        color: $descriptionTextColor;
      }
    }
    .payInputSection {
      border: 2px solid $seeSeven;
      border-radius: 5px;
      padding: 10px 15px;
      .desc {
        p {
          font-weight: 500;
          font-size: 12px;
          color: $descriptionTextColor;
          &:nth-child(2) {
            color: $fade5;
            font-size: 14px;
          }
        }
        .PayGoPaymentInput {
          border-bottom: 1.5px solid rgba(39, 61, 242, 0.49);
          width: 40%;
        }
      }
      .payGoPriceDisplay {
        background: rgba(212, 216, 252, 0.2);
        border-radius: 5px;
        height: 70px;
        min-width: 100px;
        h3 {
          font-weight: 600;
          font-size: 24px;
          color: #000;
        }
      }
    }
  }
}
