@import './variables.scss';
.emmaccen {
  .forgotPasswordContainer {
    .carousel {
      height: 100vh !important;
    }
    .formContent {
      padding: 10px 0px;
      & > div {
        height: 100%;
      }
    }

    .margin-t-b {
      display: none;
    }

    .forgtPwdTitle {
      @media (max-width: 500px) {
        padding: 30px 0;
      }

      .title {
        text-align: center;
        font-family: 'Paralucent-Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
      }
  
      .lets-get-your-account-back-lg {
        text-align: center;
        
        @media (max-width: 500px) {
          font-family: 'Paralucent-Medium';
          color: #374049;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
    }

}
