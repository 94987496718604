@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
}

.trx_loading {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
