@import 'styles/variables.scss';
@import 'styles/globals.scss';
@import '../CheckoutCard/style.scss';

.updatePlanContainer {
  // Defaylt Styling
  p {
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 24px;
    color: #45505b;
    font-weight: 600;
    margin-bottom: 32px;
  }
  h4 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: #000000;
  }
  hr {
    margin-bottom: 20px;
  }
  .ant-switch {
    border-radius: 100px !important;
  }
  .planFormWrapper {
    display: flex;
    gap: 30px;
    .switch {
      background-color: #273df2;
      border-radius: 20px;
      margin-right: 10px;
    }
    .planWrapper {
      flex: 1;
      max-width: 450px;
      .billingCycle {
        font-weight: 500;
        font-size: 18px;
        color: #717171;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        p {
          margin-right: 10px;
        }

        .promotion {
          font-weight: 400;
          font-size: 14px;
          color: #fea800;
        }
      }

      .plans {
        .planType {
          border: 2px solid #c7c7c7;
          border-radius: 10px;
          padding: 20px;
          margin-bottom: 10px;
          .headerIcon {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 12px;
            h5 {
              font-weight: 600;
              font-size: 14px;
              color: #8e8e8e;
              text-transform: uppercase;
            }
            svg {
              display: none;
            }
            .promotion {
              display: block;
              font-weight: 500;
              color: #fea800;
            }
          }

          .creditsPriceWrapper {
            .credits,
            .price {
              font-weight: bold;
              font-size: 24px;
              line-height: 64.52%;
              letter-spacing: -0.055em;
              color: #000000;
              display: flex;
              align-items: center;

              .emailCredits {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #717171;
                letter-spacing: 0;
                margin-left: 5px;
              }
              .cycle {
                font-weight: 600;
                font-size: 10px;
                color: #000000;
              }
            }
          }
        }
        .comparePlans {
          display: flex;
          justify-content: flex-end;
          font-weight: 500;
          font-size: 14px;
          color: #273df2;
        }
      }

      .autoRenewWrapper {
        margin-top: 35px;
        margin-bottom: 25px;
        h5 {
          font-weight: bold;
          font-size: 14px;
          color: #383838;
        }
        .textSwitchWrapper {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 14px;
            color: #717171;
          }
        }
      }
      .divider {
        width: 75%;
        margin: 30px auto 25px auto;
        p {
          font-weight: 600;
          font-size: 18px;
          color: #8e8e8e;
        }
      }
      .paygWrapper {
        .paygHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          margin-bottom: 15px;

          h5 {
            font-size: 18px;
            color: #1c1c1c;
          }
          .autoRenew {
            p {
              font-size: 14px;
              color: #717171;
              margin-right: 10px;
            }
          }
        }
        .payg {
          padding: 20px;
          border: 2px solid #8e8e8e;
          border-radius: 5px;
          p {
            font-weight: 500;
            font-size: 12px;
            color: #717171;
            margin-bottom: 5px;
          }
          .selectQuantityWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .selectQuantity {
              h6 {
                font-weight: 500;
                font-size: 14px;
                color: #1c1c1c;
                margin-bottom: 10px;
              }

              input {
                border: none;
                border-bottom: 1.5px solid rgba(39, 61, 242, 0.49);
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #000;
                width: 100px;
                padding: 3px 5px;
              }
              input::placeholder {
                color: #f2f2f2;
              }
            }
            .price {
              background: rgba(212, 216, 252, 0.2);
              border-radius: 5px;
              height: 60px;
              width: 100px;
              display: grid;
              place-content: center;
              p {
                font-weight: 600;
                font-size: 24px;
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
  .formWrapper {
    flex: 1;
    max-width: 450px;
    h2 {
      margin-top: 10px;
      color: #383838;
      margin-bottom: 24px;
    }
    h6 {
      font-weight: 600;
      font-size: 18px;
      color: #383838;
      margin-bottom: 20px;
    }
    .savedCardsWrapper {
      .emptyState {
        border: 2px solid #c7c7c7;
        border-radius: 10px;
        padding: 30px 20px;
        margin-bottom: 30px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
      }

      .selected_card__border {
        border: 2px solid #273df2 !important;
      }
      .cardWrapperDisabled {
        cursor: not-allowed;
      }
      .cardWrapper {
        display: flex;
        background-color: #fbfbfb;
        border: 2px solid #e3e3e3;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #383838;
        position: relative;
        cursor: pointer;

        .innerDiv {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: none;
          svg {
            margin-right: 10px;
            margin-top: 8px;
            height: 15px;
            width: 15px;
          }
        }

        .select_card {
          display: flex;
          justify-content: flex-end;
        }
        .cardSvgWrapper {
          flex: 0.4;
        }
        .cardType {
          font-weight: 500;
          text-transform: capitalize;
        }
        .cardStatus {
          margin-right: 10px;
        }
        .delete {
          color: #db2222;
        }
        .cardStatusEditWrapper {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-weight: 500;
        }
        .cardOuterWrapper {
          margin-left: 10px;
        }
      }
    }
    .accordion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
      margin-bottom: 30px;
      cursor: pointer;
      h6 {
        margin-bottom: 0px;
      }

      .chevronFaceUp {
        transform: none;
      }
      .chevronFaceDown {
        transform: rotate(180deg);
      }
    }
    .billingInfo {
      font-weight: 600;
      font-size: 16px;
      color: #8e8e8e;
      margin-bottom: 20px;
    }
  }
  .selectedPlan {
    border: 2px solid #273df2 !important;
    .promotion {
      display: none !important;
    }
    svg {
      display: block !important;
    }
  }

  .saved_cards {
    .empty {
      border: 2px solid #c7c7c7;
      border-radius: 10px;
      padding: 15px 10px;
      margin-bottom: 30px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }
  }

  // *****************************************
  //  Max Width - 1030px
  // *****************************************
  @media (max-width: 1030px) {
    .planFormWrapper {
      gap: 60px !important;
    }
    .formWrapper {
      margin-bottom: 60px !important;
    }
  }

  // *****************************************
  // Max Width - 900px
  // *****************************************
  @media (max-width: 900px) {
    .planFormWrapper {
      flex-direction: column;
    }
    .credits {
      flex-wrap: wrap !important;
    }
  }

  // *****************************************
  // Max Width - 425px
  // *****************************************
  @media (max-width: 425px) {
    .planFormWrapper {
      flex-direction: column;
      .billingCycle {
        font-size: 16px !important;
      }
      .price,
      .credits {
        font-size: 20px !important;
      }
    }
    .paygHeader h5 {
      font-size: 16px !important;
    }
    .divider {
      width: 90% !important;
      p {
        font-size: 16px !important;
      }
    }
  }
}
