@import 'styles/variables.scss';
.plansContainer {
  width: 95%;
  .topupUserPlanWrapper {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    .topupWrapper {
      flex: 1;
      background: #ffffff;
      box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 20px;
      font-weight: 600;
      h6 {
        font-size: 18px;
        line-height: 22px;
        color: $fade4;
        margin-bottom: 3px;
      }
      .availableUnits {
        margin-bottom: 20px;
        p {
          font-size: 30px;
          color: #1c1c1c;
          .totalUnits {
            font-weight: 500;
            color: #aaaaaa;
          }
        }
      }
      .topupCtaWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .paygBalance {
          h6 {
            font-size: 15px;
          }
          p {
            font-size: 20px;
            color: #1c1c1c;
            .totalUnits {
              font-weight: 500;
              color: #aaaaaa;
            }
          }
        }
        button {
          background: $primaryColor;
          border-radius: 4px;
          font-weight: 500;
          font-size: 16px;
          color: #f7f7f7;
          padding: 8px 26px;
        }
      }
    }
    .planWrapper {
      flex: 1;
      background: #ffffff;
      box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 20px;

      .priceCtaWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .planType {
          border-radius: 5px;
          padding: 6px 16px;
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
          text-transform: uppercase;
        }

        .free_plan_background {
          background: #2097f6;
        }
        .paid_plan_background {
          background: #37b34a;
        }
        .priceWrapper {
          .priceTag {
            display: flex;
            color: #000000;
            .currency {
              font-weight: 600;
              font-size: 18px;
              line-height: 38.73px;
              margin-top: -3px;
            }
            p {
              font-weight: bold;
              font-size: 32px;
              line-height: 38.73px;
            }
          }
          .cycle {
            font-weight: 500;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            text-align: right;
          }
        }
      }
      .nextPaymentUpdateCtaWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .nextPayment {
          h6 {
            font-weight: 500;
            font-size: 12px;
            color: $fade4;
          }
          p {
            font-weight: 600;
            font-size: 18px;
            text-transform: capitalize;
            color: #45505b;
          }
        }
        button {
          background: #ffffff;
          border: 1px solid #383838;
          box-sizing: border-box;
          border-radius: 4px;
          font-weight: 500;
          font-size: 16px;
          color: #383838;
          padding: 8px 26px;
        }
      }
    }
  }

  .billingHistoryTable {
    h5 {
      font-weight: 500;
      font-size: 20px;
      color: #45505b;
      margin-top: 50px;
      margin-bottom: 30px;
    }
    .ant-table table {
      width: 985px;
    }
    thead.ant-table-thead {
      width: 985px;
      height: 50px;
      border-radius: 5px;
      text-align: left;

      th {
        font-weight: 700;
        font-size: 16px;
        color: #45505b;
        background: #f8f8f8;
      }
      tr:first-child th:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      th {
        border: 0px;
      }
    }
    tbody.ant-table-tbody {
      width: 985px;
      height: 45px;
      td {
        font-weight: 400;
        font-size: 20px;
        color: #45505b;
        border: 0px;
        text-transform: capitalize;
      }

      tr:nth-child(even) {
        td {
          background-color: #fbfbfb;
        }
      }
    }
  }
  .psa {
    font-size: 16px;
    color: $fade4;
    margin-top: 30px;
  }
  .cancelSubscription {
    display: flex;
    justify-content: flex-end;
    button {
      font-weight: 500;
      padding: 8px 26px;
      &:disabled {
        cursor: not-allowed;
      }
    }
    .cancel_sub {
      color: #f45b5b;
      border: 1px solid #f45b5b;
    }

    .renew_sub {
      color: green;
      border: 1px solid green;
    }
  }

  // *****************************************
  // GLOBAL MEDIA QUERY
  // *****************************************

  // *****************************************
  //  Max Width - 900px
  // *****************************************
  @media (max-width: 900px) {
    .topupUserPlanWrapper {
      flex-direction: column;
    }
    .topupWrapper,
    .planWrapper {
      width: 100%;
    }
  }

  // *****************************************
  // Max Width - 425px
  // *****************************************
  @media (max-width: 425px) {
    .topupUserPlanWrapper {
      .topupWrapper {
        h6 {
          font-size: 16px;
        }
        .topupCtaWrapper {
          .availableUnits {
            p {
              font-size: 24px;
            }
          }
          button {
            font-size: 14px;
          }
        }
      }
      .planWrapper {
        .nextPaymentUpdateCtaWrapper {
          button {
            font-size: 14px;
          }
        }
      }
    }

    .billingHistoryTable {
      h5 {
        font-size: 18px;
      }
      thead.ant-table-thead th {
        font-size: 14px;
      }

      tbody.ant-table-tbody td {
        font-size: 16px;
      }
    }

    .expiryDate {
      font-size: 16px !important;
    }
    .psa {
      font-size: 14px;
    }
    .cancelSubscription {
      margin-top: 10px;
      button {
        font-size: 14px;
      }
    }
  }
}
