@import './variables.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.first_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  border-bottom: 1px solid rgba(227, 227, 227, 1);
  

  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 1rem;

    .button{
      width: 100%;
      display: flex; /* Ensures flexibility in alignment */
    justify-content: flex-end; 
    }
  }

}
.content {
  width: 100%;
  display: flex;
}


.antd_timeline {
  width: 100%;
  font-family: 'Paralucent-Medium';
  display: flex !important; 
  flex-direction: row !important; 
  justify-content: space-between !important; 

  
}



@media screen and (max-width: 400px) {
  .antd_timeline {
    transform: scale(0.8);
    margin-left: -35px;
    margin-top: 10px;

  }
}

.back_mobile_desktop,
.timeline {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timeline1,
.timeline2,
.timeline3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Paralucent-Medium';
  font-size: 14px;
}
.timeline1:active {
  color: rgba(39, 61, 242, 1);
}
.timeline1 > p:first-child,
.timeline2 > p:first-child,
.timeline3 > p:first-child {
  width: 22px;
  height: 24px;
  border-radius: 42px;
  background: rgba(199, 199, 199, 1);
  color: #fff;
  font-family: 'Paralucent-Medium';
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;
}
.width {
  width: 30px;
  height: 2px;
  background: rgba(189, 189, 189, 1);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.back {
  background-color: rgba(242, 243, 254, 1);
  border-radius: 37px;
  padding: 10px;
  margin-right: 0.7rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  p {
    color: rgba(39, 61, 242, 1);
    font-size: 12px;
    font-family: 'Paralucent-Medium';
  }

  @media screen and (max-width: 1106px) {
    width: 40px;
    height: 40px;
    border-radius: 0;
    background-color: transparent;
    justify-content: center;
    padding: 0;

    p {
      display: none; // Hide text
    }

    // Style for the BackbuttonIcon
    svg {
      width: 24px;
      height: 24px;
      
    }
  }
}
p + p {
  margin-top: 0px;
}

.empty_container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-family: 'Paralucent-Light';
    color: #bebebe;
    font-size: 18px;
  }
}

.edit_container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.first_child {
  width: 30%;
  // 336px;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.first_child > p:first-child {
  margin-bottom: 1rem;
  color: rgba(28, 28, 28, 1) !important;
  font-size: 14px !important;
  font-family: 'Paralucent-Light' !important;
}

.hero_container_text {
  margin-bottom: 1rem;
  color: #8e8e8e !important;
  font-size: 14px !important;
  font-family: 'Paralucent-Light' !important;
}
.dragger {
  background-color: rgba(242, 243, 254, 1);
  height: 60px;
}
.ant_upload_text {
  color: #717171 !important;
  font-size: 14px !important;
  margin-bottom: 2rem;
  font-family: 'Paralucent-Light' !important;
}
:where(.css-dev-only-do-not-override-12jzuas).ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
  border: 1px dashed rgba(39, 61, 242, 1);
}
.color {
  color: rgba(39, 61, 242, 1);
}
.second_child {
  width: 70%;
  background: rgba(246, 246, 246, 1);
  padding: 2rem;
}
.preview_second_child {
  width: 70%;
  height: 100%;
  padding: 2rem;
  margin: auto;
}
.new {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 2rem;
  width: 100%;
}

.new_status {
  background-color: rgba(249, 245, 254, 1);
  height: 41px;
  width: 59px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75rem;
  color: #9a4df9;
  font-family: 'Paralucent-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
.old_status {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  color: rgba(113, 113, 113, 1);
  font-family: 'Paralucent-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
.ant-btn .css-dev-only-do-not-override-12jzuas .ant-btn-primary .ant-btn-lg .btnPrimary .btn_status {
  width: '84px';
  height: '34px';
  text-align: 'center';
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_container {
  width: 100%;
  padding: 14px 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 0.5px solid var(--Neutral-100, #e3e3e3);
  margin-bottom: 0.75rem;
  text-align: left;
  position: relative;
}

.dropdown_toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  
  .dots {
    font-size: 14px;
    color: #717171;
    transform: rotate(90deg);
    display: inline-block;
  }
}
.status {
  background-color: rgba(55, 179, 74, 0.1);
  border-radius: 12px;
  color: #37b34a !important;
  font-size: 12px;
  text-align: center;
  height: 21px;
  padding: 4px 6px !important;
  font-family: 'Paralucent-Medium';
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
}

.publishStatus {
  color: rgba(142, 142, 142, 1);
  background-color: rgba(241, 241, 241, 1);
  font-size: 12px;
  border-radius: 12px;
  text-align: center;
  height: 21px;
  padding: 4px 6px !important;
  font-family: 'Paralucent-Medium';
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
}

.part_one > p {
  font-size: 16px;
  color: #383838;
  font-family: 'Paralucent-Medium';
}

.dropdown_content{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.part_one {
  display: flex;
  align-items: center;

  justify-content: center;
  gap: 5;
}
@media screen and (max-width: 620px) {
  .dropdown_toggle {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .part_two {
    display: none;
    width: max-content;
    
    &.show_dropdown {
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      background: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      z-index: 100;
      padding: 10px;
    }
  }

  .dropdown_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    

    .publishedStatus,
    .unpublishedStatus {
      width: 100%;
      justify-content: center;
    }
  }

  .part_one {
    padding-right: 40px;
  }
}
.first_section {
  width: 100%;
}
.second_section {
  background-color: rgba(246, 246, 246, 1);
}
.space {
  display: flex;
  align-items: center;
  p {
    margin-left: 0.75rem;
  }
}
.next_button {
  background: rgba(39, 61, 242, 1);
  color: #fff;
  font-family: 'Paralucent-Medium';
  font-size: 14px;
  width: 82px;
  margin-left: 0.7rem;
}

.announcement {
  width: 100%;
  height: 95px;
  // background-color: #C7EACC;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(1, 134, 21, 1);
  font-weight: 400;
  font-family: 'Paralucent-Light';
  font-size: 14px;
}
.ant-tabs-nav {
  display: flex;
}
.flex_container {
  display: grid;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  grid-template-columns: repeat(2, 1fr);
  background-color: #fff;
  gap: 2rem;
}
.heading {
  font-size: 32px;
  font-weight: 800;
  font-family: 'inter';
  margin: 0 !important;
  padding: 0 !important;
}
.design_content {
  width: 100%;
}
.preview_design_content {
  width: 100%;
}
.design_body {
  font-family: 'Paralucent-light';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(170, 170, 170, 1);
  margin-top: 0.3rem;
}
.form {
  margin-top: 1.5rem;
}
.input {
  border: 1px solid #c7c7c7;
  color: #c7c7c7;
  border-radius: 22px;
  padding: 9px 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Paralucent-light';
}
input:focus,
input:focus-within {
  color: #000;
}
.subscribe_button {
  // background: rgba(1, 134, 21, 1);
  width: 160px;
  font-family: 'Paralucent-medium';
  color: #fff !important;
  margin-top: 2rem;
}
.footer {
  width: 100%;
  //background: #C7EACC;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 3rem;
  // position: absolute;
  // bottom: 0;
}
.social_media_icons {
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;
}
.social_media_switch_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}
.margin_left {
  display: flex;
  font-size: 12px;
  font-family: 'Paralucent-Medium';
  color: rgba(113, 113, 113, 1);
  padding: 0.5rem;
  margin-left: 1rem;
}
.margin_left > p:nth-child(2) {
  margin-left: 2rem;
}
.input_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 14px;
    font-family: 'Paralucent-Light';
    color: rgba(28, 28, 28, 1);
  }
}
.first_child_input,
.social_media_switch_container > Input {
  color: rgba(131, 113, 113, 1);
  width: 105px;
  height: 32px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
input[type='color']:focus,
.first_child_input:focus {
  outline: none;
}
.social_media_switch_container > Input {
  width: 233px;
  margin-right: 2rem;
}
.switch {
  border-radius: 16px;
}

.hero_container {
  border-top: 1px solid #f2f2f2;
  padding-top: 1.5rem;
  margin-bottom: 0.7rem;
}
.hero_container > p:nth-child(2) {
  color: #383838;
  font-size: 14px;
  font-family: 'Paralucent-Light';
  margin-bottom: 0.75rem;
}

.input_color {
  height: 32px;
  width: 50%;
  outline-color: none;
  margin-left: 0.5rem;
  border: 0.5px solid #c7c7c7;
  border-radius: 2px;
}
.color_box {
  margin-bottom: 1rem;
}
.label {
  font-family: 'Paralucent-Light';
  font-size: 14px;
  color: rgba(56, 56, 56, 1);
  line-height: 16.8px;
}
.hero_input {
  border: 0.5px solid #c7c7c7;
  color: rgba(119, 119, 119, 1);
  font-family: 'Paralucent-Light';
  font-size: 14px;
  border-radius: 2px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.close_icon_container {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 102;
}

.close_icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #717171;
}

.landing-page-action {
  .landing-page-action-header {
    padding: 40px 0 48px 30px;

    .btnLight {
      color: $fade4;
    }

    .pLight {
      color: $title2;
    }
  }
}

.publishedStatus {
  background-color: #fff !important;
  color: #5264f5 !important;
  border: 1px solid #5264f5 !important;
}

.unpublishedStatus {
  background-color: #fff !important;
  color: #717171 !important;
  border: 1px solid #717171 !important;
}

// size 16 screen
@media screen and (min-width:1998px) {
  .new {
    width: 90%;
  }
    // .content_container {
    //   padding: 20px 48px;
    // }
}

@media screen and (max-width: 768px) {
  .flex_container {
    grid-template-columns: repeat(auto-fit, 100%);
  }

  .second_child {
    width: 100%;
    background: rgba(246, 246, 246, 1);
    padding: 0;
  }
  .preview_second_child {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: auto;
  }
}

@media screen and (max-width: 1106px) {
  .first_child {
    position: fixed;
    top: 0;
    left: -100%; // Initially off-screen
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: white;
    overflow-y: auto;
    transition: left 0.3s ease-in-out;
    
    &.mobile_open {
      left: 0; // Slide in when active
    }
  }

  .second_child{
    width: 100%;
  }
  .mobile_overlay {
    display: block;
    position: absolute;
    left: 10px;
    top: -40px;
    padding: 15px;

    @media screen and (max-width: 576px) {
      top: -75px;
    }
  }
}

@media screen and (max-width: 600px) {
  .contactImgPart{
    img{
      display: none !important;
    }
    
  }
  
}