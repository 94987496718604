@import './variables.scss';

.emmaccen {
  .accountSettings {
    min-height: 400px;
    form {
      label {
        font-weight: normal;
      }
      .grid2 {
        gap: 15px;
        column-gap: 30px;
      }
    }
    .AccountSettingsContainer {
      display: grid;
      grid-template-columns: 1.5fr 3fr;
      gap: 30px;
      align-items: start;
      padding: 30px;
      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        .inputLists {
          @media (max-width: 550px) {
            grid-template-columns: 1fr;
          }
        }
      }
      .profileWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .accountAvatar {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background: $primaryColorLight;
        }
        .uploadPicture {
          position: absolute;
          //   width: 40px;
          //   height: 40px;
          padding: 7px;
          background: $primaryColor;
          color: white;
          font-size: 20px;
          right: 10px;
          bottom: 10px;
          border-radius: 50%;
          cursor: pointer;
          input {
            display: none;
          }
        }
      }
    }
    .emailConfig {
      display: flex;
      align-items: center;
      @media (max-width: 750px) {
        display: block;
      }
      & > div {
        margin-right: 30px;
      }
    }
    .apiContainer {
      min-height: 200px;
      .apicontentWrapper {
        padding: 20px;
        @media (max-width: 1100px) {
          display: block;
          & > div:last-child {
            margin-top: 20px;
          }
        }
        .apiKeys {
          padding: 15px;
          margin-top: 15px;
          overflow-y: auto;
          * {
            word-wrap: break-word;
          }
        }
        .toggleIcon {
          border-left: 1px solid #efefef;
          margin-left: 10px;
        }
        .ant-typography {
          display: flex;
          margin-bottom: 0px;
        }
        svg {
          font-size: 22px;
        }
      }
    }
    .accountSecurity {
      padding: 40px 20px;
      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }
      .dangerSection {
        p {
          color: #ff4d4f;
        }
      }
      button {
        margin-top: 10px;
      }
    }
  }
}
