@import '../../styles/variables.scss';

.boomshakar.builder-select-template {
  .builderBreadCrumbContainer {
    margin-top: -8px;
    padding: 16px 30px;
    border-bottom: 1px solid $shade1;
  }

  .select-template-header {
    color: $fade2;
    padding: 40px 0 24px 30px;
  }

  .mail_subject__template_select {
    .template-section {
      margin-top: 40px;

      .template-card:first-child {
        margin-left: 0;
      }
    }

    .ant-tabs {
      .ant-tabs-nav {
        border-bottom: none;
        background-color: #fbfbfb;
        padding: 2px 0 0 30px;

        .ant-tabs-nav-list {
          font-family: 'Paralucent-Light';
          font-weight: 18px;
          color: #555;
          display: flex;
          gap: 1rem;

          .ant-tabs-tab-btn {
            transition: none;
          }

          .ant-tabs-tab-active {
            font-family: 'Paralucent-Medium';
          }

          // .ant-tabs-tab-active::before{
          //   content: '';
          //   position: absolute;
          //   bottom: 0;
          //   height: 4px;
          //   width: 100%;
          //   background: $primaryColor;
          //   border-radius: 2px;
          //   z-index: 5;
          // }
        }
      }

      .ant-tabs-content-holder {
        padding: 0 30px;
      }
    }

    .ant-divider {
      display: none;
    }
  }
}

.subject_of_mail_view {
  .header-title {
    font-size: 24px;
    color: #45505b;
  }

  .templateContaner {
    overflow-y: auto;
    // padding: p-4 sm:p-20 sm:pt-12 pt-12 relative mt-5 h-full w-full;
    padding-top: 42px;

    .email-subject-mention {
      max-width: 100%;
      .email-subject-mention__input {
        height: 49px;
        padding: 4px 11px;
      }
    }

    @media (max-width: 800px) {
      padding-top: 20px;
    }

    svg {
      transition: $ease;
      margin: 0px 5px;
      font-size: 18px;
    }

    .mailList,
    .campaignePreview {
      max-height: 450px;
    }

    @media (max-width: 900px) {
      height: auto;

      & .grid2 {
        grid-template-columns: 1fr;
      }

      .campaignePreview {
        display: none;
      }
    }

    .mailList {
      margin-left: 30px;

      @media (max-width: 800px) {
        margin-left: 20px;
      }

      .subject_of_mail_form {
        max-width: 450px;
        width: 100%;
        margin-top: 32px;
        margin-bottom: 80px;

        &__item {
          .ant-form-item-label label,
          .ant-form-item-required {
            &::before {
              content: '';
            }

            font-family: 'Paralucent-Light';
            font-size: 18px;
            color: $fade555;
          }

          .ant-input {
            color: $fade555;
            font-family: 'Paralucent-Light';
          }

          .ant-form-item-explain-error {
            font-family: 'Paralucent-Light';
          }
        }
      }

      .selected {
        border: 1px solid rgba(39, 61, 242, 0.3);

        &:hover {
          padding: 15px !important;
        }
      }

      .card {
        // transition: $ease;
        padding: 15px;
        min-height: 200px;
        max-height: 300px;
        margin: 0px 10px 20px 0px;

        &:hover {
          border: 1px solid rgba(39, 61, 242, 0.3);
          padding: 14px;
        }

        .bold {
          margin-bottom: 10px;
          font-size: 18px;
        }

        // .actionIcons {
        //   svg {
        //     transition: $ease;
        //     margin-left: 10px;
        //     font-size: 18px;
        //     cursor: pointer;
        //     &:hover {
        //       color: $primaryColorLight;
        //     }
        //   }
        // }
      }
    }

    .campaignePreview {
      border: 1px solid $shade1;
      padding: 15px;
      // background-color: #fff;
      border-radius: $radius;

      .previewText {
        padding-bottom: 10px;
        border-bottom: 1px solid $shade1;
      }

      .userProfile {
        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }

        p {
          margin: 0px;
          font-size: 13px;
        }
      }
    }
  }
}

svg {
  vertical-align: baseline;
}

.boomBreadCrumbContainer {
  white-space: nowrap;
  margin: -1rem 1rem 0.5rem 1rem;

  .crumb {
    margin-right: 10px;
    color: $fade4;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    svg {
      margin-left: 10px;
    }
  }

  & .crumb:last-child svg {
    display: none;
  }
}

.ant-modal-wrap.attach_modal {
  .ant-modal-title {
    text-align: center !important;
    font-weight: 600 !important;
  }

  .ant-modal-footer {
    display: flex !important;
    justify-content: space-between !important;
    // text-align: left !important;
  }
}

//Create/Load/Select template
.mail_subject__template_select {
  .ant-tabs .ant-tabs-nav {
    background: #fbfbfb;

    .ant-tabs-tab {
      padding: 8px 8px;
      margin: 0;

      h5 {
        color: #555;
      }

      &-active {
        h6,
        h5 {
          color: $primaryColor;
          font-family: 'Paralucent-Light';
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: $primaryColor;
    }
  }

  .actionBtns {
    #btn--type-primary_md {
      width: fit-content;
      font-family: 'Paralucent-Medium';
    }
  }

  .ant-tabs-content-holder {
    margin-bottom: 0;
    padding: 40px 30px;
  }
}

.preview_template {
  .ant-tabs .ant-tabs-nav {
    background: #fbfbfb;

    .ant-tabs-tab {
      padding: 8px 8px;
      margin: 0;

      h5 {
        color: #555;
      }

      &-active {
        h6,
        h5 {
          color: $primaryColor;
          font-family: 'Paralucent-Light';
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: $primaryColor;
    }
  }
}

.templatePane {
  // border: 1px solid $shade1;
  // padding: 0px 10px;
  margin: 0px 0px;
  gap: 25px 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(188px, 1fr));
}

.templatePane::after {
  content: '';
  display: table;
  clear: both;
}

.template-card {
  border: 1px solid $shade1;
  height: 194px;
  padding: 17px;
  border-radius: 5px;
  transition: all 0.3s ease-in;
  cursor: pointer;

  &:hover,
  &.active {
    border: 1px solid $primaryColor;
    // background: #f2f3fe;
  }

  .temp_card_contain {
    position: relative;
    height: 100%;
    overflow: hidden;

    .temp_img {
      opacity: 1;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      transition: 0.5s ease;
      backface-visibility: hidden;
      object-fit: cover;
    }

    .temp_overlay {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      // background: rgb(0, 0, 0);
      // background: rgba(0, 0, 0, 0.35);
      /* Black see-through */

      &__content {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        color: #fff;

        & button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 4px 6px;
          border-radius: 10px;

          &#btn--type-primary_default {
            width: fit-content;
            font-family: 'Paralucent-Light';
          }
        }
      }
    }

    .typography--variant-pLight {
      color: $fade555;
    }
  }

  &:hover .temp_card_contain .temp_img {
    opacity: 0.3;
  }

  &:hover .temp_card_contain .temp_overlay {
    opacity: 1;
  }
}

.t-card {
  width: 188px;
}

// @media screen and (max-width: 1170px) {
//   .template-card {
//     width: calc(20% - 20px);
//   }
// }
// @media screen and (max-width: 970px) and (min-width: 600px) {
//   .template-card {
//     width: calc(40% - 20px);
//   }
// }
// @media screen and (max-width: 599px) {
//   .template-card {
//     width: calc(95% - 20px);
//   }
// }
.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}

.panel__editor {
  position: initial;
}

.gjs-four-color-h:hover {
  color: $primaryColor !important;
}

.gjs-pn-btn:hover {
  color: $primaryColor !important;
}

.gjs-one-bg {
  background-color: $shade1 !important;
}

.gjs-pn-active {
  color: $primaryColor !important;
}

.gjs-two-color {
  color: #1c1c1c !important;
}

.gjs-three-bg {
  background-color: #1e8fe1 !important;
  color: white;
}

.gjs-block-category .gjs-title,
.gjs-traits-label,
.gjs-trt-traits,
.gjs-layer-title-inn {
  font-weight: 600;
}

.gjs-sm-sector-label {
  font-size: 14px !important;
  font-family: 'Inter' !important;
  font-weight: 500;
}

.gjs-sm-icon {
  font-size: 14px !important;
  font-family: 'Inter' !important;
  font-weight: 500;
}

.gjs-pn-views-container {
  font-family: 'Inter' !important;
  font-weight: 500;
  width: 20% !important;
  z-index: 1 !important;
}

// .gjs-pn-options {
//   z-index: 1;
// }
.gjs-frame-wrapper {
  /* width */
  ::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius: 10px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primaryColorLight !important;
    border-radius: 10px !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primaryColor !important;
  }
}

.gjs-frame-wrapper__top {
  width: 80% !important;
}

.gjs-cv-canvas {
  width: 80% !important;
}

.gjs-frame-wrapper__top {
  width: 80% !important;
}

.gjs-layer-name--no-edit {
  //fixate height on the layer panel text size
  height: 20px !important;
}

.gjs-four-color {
  color: $primaryColor !important;
}

.gjs-pn-btn.gjs-pn-active,
.gjs-cv-canvas__frames {
  background-color: #f1f2fd !important;
}

.gjs-frame {
  margin: 1rem !important;
  width: 98% !important;
  height: 96% !important;
}

.gjs-category-title,
.gjs-layer-title,
.gjs-block-category .gjs-title,
.gjs-sm-sector-title {
  background-color: #fff !important;
  border-bottom: 1px solid #f2f2f2 !important;
}

.gjs-field {
  // background-color: #f1f2fd !important;
  border: 1px solid #e7e7e7 !important;
}

#gjs-clm-add-tag,
.gjs-clm-tags-btn {
  color: $primaryColor !important;
}

.gjs-pn-panel.gjs-pn-options.gjs-one-bg.gjs-two-color {
  color: #c7c7c7 !important;
}

.gjs-pn-options {
  right: 50% !important;
  background-color: #fff !important;
}

.gjs-pn-commands,
.gjs-pn-views,
.gjs-pn-devices-c,
.gjs-blocks-cs,
.gjs-blocks-cs .gjs-block,
.gjs-pn-views-container {
  background-color: #fff !important;
}

//newsletter redo and udo btn
.gjs-pn-panel.gjs-pn-commands .gjs-pn-buttons {
  justify-content: right !important;
  position: relative;
  right: 35% !important;
  color: #c7c7c7 !important;
  overflow-x: hidden !important;
}

// After customadd of Basic block,
// display none of Basic div grids

.gjs-block-categories {
  .gjs-fonts.gjs-f-b1,
  .gjs-fonts.gjs-f-b2,
  .gjs-fonts.gjs-f-b3,
  .gjs-fonts.gjs-f-b37 {
    display: none !important;
  }
}

.gjs-clm-tags,
.gjs-sm-sectors,
.gjs-sm-properties {
  background-color: #fff !important;
}

.gjs-field-integer input,
.gjs-clm-tags #gjs-clm-tags-field,
.gjs-field.gjs-select {
  background-color: #fafbfc !important;
  color: #000 !important;
}

#gjs-clm-tags-c .gjs-clm-tag {
  color: $primaryColor !important;
  background-color: #f1f2fd !important;

  #gjs-clm-checkbox,
  #gjs-clm-close {
    color: $primaryColor !important;
  }
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 22px;
  width: 88%;
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.preview-templates-btn {
  display: flex;
  padding: 8px 26px;
  width: 70%;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 4px !important;
  font-family: 'Paralucent-Light';
  height: 40px;
}

.preview-templates-btn.fill {
  background-color: #273df2;
  color: #f7f7f7;
}

.preview-templates-btn.outline {
  border: 1px solid $shade1;
  color: #45505b;
  background: transparent;

  &:hover {
    border-color: #273df2 !important;
    color: #273df2 !important;
  }
}

.temp_inner {
  left: 50%;
  top: 50%;
  position: relative;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.emptyBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  flex-direction: column;

  .emptyText {
    font-family: Paralucent-Light;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #8e8e8e;
  }
}

.esdev-app .sripo-watermark {
  display: none !important;
}
