:root {
  --sideBarWidthContactActive: 336px;
}

.sidebar-campaign {
  width: var(--sideBarWidthContactActive) !important;
}
.left-arrow {
  font-size: 24px;
}
.sidebar-header {
  border-bottom: 1px solid #f2f2f2;
}
.sidebar-header p {
  padding-bottom: 10px !important;
  margin: 0 40px !important;
}

// .edit-page-fix {
//   padding: 0 !important;
// }
.sidebar-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
}
.sidebar-description {
  color: #aaaaaa;
  font-size: 12px;
}

.campaign-sidebar {
  max-width: var(--sideBarWidthContactActive);
  color: #555555;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.sidebar-left-arrow {
  color: #000 !important;
}

.form-switch {
  border-radius: 100px !important;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  max-width: 50px !important;
  // background-color: #F2F2F2 !important;
}

.form-item-parent {
  .border {
    border-radius: 0 !important;
  }

  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-row.ant-form-item {
    margin: 0;
  }

  // button.ant-switch.form-switch.ant-switch-checked.ant-switch-disabled {
  //   background: #f4f4f4;
  // }
}
.confirmation-page {
  border: 1px solid #f2f2f2;
  // border-top: none;
  border-radius: 3px;
  width: 305px;
  padding-top: 0;
  // height: 334px;
}

.ant-row.ant-form-item {
  margin: 15px 5px !important;
}
.input-label label {
  font-weight: 500 !important;
}
.right-icon {
  margin: 10px 0;
}


.add-fields-modal{
  .error-text{
    margin-top: 3px;
    font-size: 14px;
  }
}