.SelectContainer{
    width: 348px;
    height: 44px;
    padding: .75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    font-family: 'Paralucent-Light';
    font-size: 14px;
    color: #e3e3e3;
}

.DropdownContainer{
    width: 348px;
    min-height: 20%;
    padding: .75rem;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    margin-top: .5rem;
    margin-bottom: .5rem;
    position: absolute;
    z-index: 10;
    background: #fff;
}

.DropdownValue{
    padding: .5rem;
    font-family: 'Paralucent-Light';    
}

.DropdownValue:hover{
    cursor: pointer;
}

.DropdownValue:focus, .DropdownValue:hover{
    background-color: #f2f2f2;
}

.font{
    font-size: 14px;
    font-family: 'Paralucent-Light';
    margin-top: 1rem;
}

.value{
    border: 1px solid #273df2;
    border-radius: 24px;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
   width: fit-content;
    align-items: center;
    font-family: 'Paralucent-Light';
    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: .25rem;
}

.DropdownValueContainer{
    width: 100%;
    height: 50%;
    display: flex;
    flex-wrap: wrap !important;
}

.value>img:hover{
    cursor: pointer;
}

