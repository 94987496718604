@import './variables.scss';

.emmaccen {
  .verifySectionContainer {
    width: 100%;
    min-height: 100vh;
    @media (max-width: 500px) {
      display: block;
    }
  }
  .verifySectionInnerContainer {
    padding: 32px 40px;
    box-shadow: 0px 5px 50px rgba(5, 1, 21, 0.05);
    border-radius: 10px;
    max-width: 450px;
    margin: auto;

    @media (max-width: 500px) {
      margin-top: 78px;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
    .textWrapper {
      padding: 12px 16px;
      background: rgba(8, 207, 135, 0.1);
      border-radius: 5px;
    }
    button {
      &:disabled {
        color: #fff;
      }
      &:hover {
        background-color: $primaryColor;
      }
    }

    .title {
      @media (max-width: 500px) {
        font-size: 24px;
        font-weight: 400;
        color: #000;
        font-family: 'Paralucent-Medium';
      }
    }

    .submit-btn-lg {
      @media (max-width: 500px) {
        display: none;
      }
    }
  }
  .submit-btn-mobile {
    display: none;
    @media (max-width: 500px) {
      display: block;
      max-width: 450px;
      margin: auto;
      margin-top: 60px;
    }
  }
}
