@import './variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner_container {
  width: 420px;
  height: 208px;
  padding: 2rem;
  // margin-top: 3rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: none;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: 'Paralucent-Medium';
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.verifyText {
  font-family: 'Paralucent-Light';
  font-size: 14px;
  font-weight: 400;
  color: #717171;
  margin-top: 1rem;
}
.clipbox {
  width: 100%;
  padding: 0 0.2rem;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.clipboard,
.modalclipboard {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Paralucent-Light';
  font-size: 14px;
  font-weight: 400;
  color: #717171 !important;
}
.modalclipboard {
  width: 302px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Paralucent-Light';
  font-size: 14px;
  font-weight: 400;
  color: #717171 !important;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin: auto;
  margin-top: 1rem;
}
.modalContainer {
  width: 385px !important;
  height: 378px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.modalButton {
  width: 321px;
  padding: 8px 26px;
  background-color: #273df2;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: 'Paralucent-Medium';
}
