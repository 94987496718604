@import './variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner_container {
  width: 412px;
  min-height: 280px;
  padding: 2rem;
  transition: ease-in-out;
  // margin-top: 3rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: flex-start;
}

.text {
  font-family: 'Paralucent-Light';
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 1rem;
}
.linked {
  font-family: 'Paralucent-Light';
  font-size: 12px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.linked_select {
  border: none !important;
  outline: none;
}
