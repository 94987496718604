@import './variables.scss';

.emmaccen {
  .signupContainer {
    .carousel {
      height: 120vh !important;
    }
    .grid2 {
      @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 0px;
      }
    }
  }
  .loginPageContainer {
    .title {
      font-size: 36px;
    }
    .margin-t-b {
      margin: 30px 0px;

      @media (max-width: 500px) {
        margin: 36px 0px;
      }
    }
    .mobile-image {
      display: none;
      @media (max-width: 800px) {
        display: block;
      }
    }
    min-height: 100vh;
    .loginSections {
      .w-50 {
        width: 50%;
      }
      .carouselArea {
        @media (max-width: 800px) {
          display: none;
        }
        .carousel {
          height: 103vh;
          background-color: rgba(39, 61, 242, 1);
          .centered {
            height: 100%;
          }
          .carouselContent {
            width: 70%;
            @media (max-width: 950px) {
              width: 90%;
            }
            h1 {
              margin-top: 15px;
              font-size: 22px;
            }
            p {
              color: #d8d8d8;
              margin-top: 15px;
            }
            * {
              color: #fff;
            }
          }
          img {
            height: 300px;
            margin: auto;
          }
        }
      }
      .formArea {
        @media (max-width: 800px) {
          padding: 5%;
          width: 100%;
        }

        @media (max-width: 500px) {
          padding: 70px 32px 0;
        }
        .formContent {
          height: 100%;
          form {
            button {
              width: 100%;
            }

            .ant-btn-primary[disabled] {
              color: rgb(210, 210, 210);

              &:hover {
                color: rgb(96, 96, 96);
              }
            }
            .tc_pp {
              font-weight: 500;
              color: $fade7;
            }
          }
          .hideForm {
            display: none;
          }

          .showForm {
            display: block;
          }

          @media (max-width: 500px) {
            .container2 {
              width: 100%;
            }
          }

          .go-back-lg {
            @media (max-width: 500px) {
              display: none;
            }
          }

          .go-back-mobile {
            display: none;
            @media (max-width: 500px) {
              display: block;
              text-align: center;
              padding: 5px 0;
            }
          }

          .maildrip_btn {
            @media (max-width: 500px) {
              margin-top: 30px;
            }
          }

          /* Start md sign up view component */
          .signUpTitleCont {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 6rem 0 4rem;
            color: #374049 !important;
            @media (max-width: 500px) {
              margin: 0 1rem 0 0rem;
            }
            @media (min-width: 520px) and (max-width: 769px) {
              margin: 0 6rem 0 4rem;
            }
            @media (min-width: 769px) and (max-width: 1025px) {
              margin: 0 1rem 0 0rem;
            }
          }

          .signUpTitle {
            font-family: 'Paralucent-Bold';
            font-size: 36px;
            line-height: 44px;
            @media (max-width: 500px) {
              font-size: 24px;
              margin-bottom: 30px;
            }
            @media (min-width: 520px) and (max-width: 1025px) {
              font-size: 30px;
            }
          }

          .googleText {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $title2 !important;
          }
          .orDivider {
            margin: 20px 0 10px;
            color: $title2 !important;

            @media (max-width: 500px) {
              display: none;
            }
          }
          .nextBtn {
            margin-bottom: 1rem;
            margin-top: 1.25rem;

            @media (max-width: 500px) {
              margin-top: 38px;
            }
          }
          .formStep {
            padding-left: 7rem;
          }

          .md-logo {
            @media (max-width: 500px) {
              display: none;
            }
          }

          .password-field{
            @media (max-width: 500px) {
              margin-bottom: 8px;
            }
          }

          .signup-progress-step-lg {
            @media (max-width: 500px) {
              display: none;
            }
          }

          .l-up-wg-lg,
          .s-up-wg-lg {
            @media (max-width: 500px) {
              display: none;
            }
          }

          .l-up-wg-mobile,
          .s-up-wg-mobile {
            display: none;
            @media (max-width: 500px) {
              display: block;
            }
          }

          .already-have-an-account-lg {
            @media (max-width: 500px) {
              display: none;
            }
          }

          .already-have-an-account-mobile {
            display: none;
            @media (max-width: 500px) {
              display: block;
              padding-top: 20px;
            }
          }

          .passwordStrengthContainer {
            display: flex;
            gap: 1rem;
            .strength {
              width: 20%;
              height: 4px;
              border-radius: 5px;
              background-color: #e0e2e9;
              &.strength-level1 {
                background-color: #fd2900;
              }
              &.strength-level2 {
                background-color: #e87200;
              }
              &.strength-level3 {
                background-color: #c4a000;
              }
              &.strength-level4 {
                background-color: #8fc500;
              }
              &.strength-level5 {
                background-color: #12e417;
              }
            }
          }
          /* End md sign up view component */
          .signUpQ {
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            letter-spacing: 0.02em;
            font-family: 'Paralucent-Medium';
            @media (max-width: 500px) {
              font-size: 14px;
              line-height: 22px;
              color: #000000;
              letter-spacing: 0.02em;
              font-family: 'Paralucent-Medium';
              padding-top: 20px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 800px) {
    .formArea {
      min-height: 100vh;
      & .title {
        font-size: 23px;
      }
    }
  }
  /* Start md login and forgot password, reset pwd view component */
  .mdForgtPwd {
    color: #273df2;
    &:hover {
      color: #273df2;
      text-decoration: underline;
    }
  }

  .forgtPwdTitle {
    padding-bottom: 2.75rem;
  }

  .resetPwdBtn {
    padding-top: 1.625rem;
  }

  .resetPwdTitle {
    padding-bottom: 2.75rem;
  }
  /* End md login and forgot password, reset pwd view component */

  .password-div{
    @media (max-width: 500px){
      margin-bottom: 16px;
    }
  }

  .forgot-password-remember-me{
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    
  }
  
  .forgot-password-remember-me > :first-child{   
      order: 2;
      font-size: 12px;
    .mdForgtPwd{
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
  .forgot-password-remember-me > :nth-child(2){
    
      order: 1;
      margin: 0;
      padding: 0;
      font-size: 12px;
    

    .remember-me{
      @media (max-width: 500px){
        font-size: 12px;
      }
    }

    .ant-form-item-control-input{
      min-height: unset;
      margin: 0;
      padding: 0;
    }
  }
}
