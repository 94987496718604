/* eslint-disable */
@font-face {
  font-family: 'HKGrotesk';
  src: local('HKGrotesk'), url('../public/fonts/HKGrotesk/HKGrotesk-RegularLegacy.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Paralucent-Bold';
  src: local('Paralucent-Bold'), url('../public/fonts/Paralucent/Paralucent-Bold.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Paralucent-Light';
  src: local('Paralucent-Light'), url('../public/fonts/Paralucent/Paralucent-Light.otf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: 'Paralucent-Medium';
  src: local('Paralucent-Medium'), url('../public/fonts/Paralucent/Paralucent-Medium.otf') format('opentype');
  font-weight: normal;
}

body {
  /* font-family: 'HKGrotesk', sans-serif !important; */
  font-family: 'Inter', sans-serif !important;
  /* font-family: 'Paralucent-Medium', sans-serif !important; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/* Styling for placeholder class*/
.placeholder-class{
  color: #575757;
}
/* Styling for placeholder class*/

/* Styling for freshdesk positioning*/
#fc_frame {
  /* display: none; */
  right: 0 !important;
  left: 15px !important;
}
/* Styling for freshdesk positioning ends*/

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.ant-tabs-content-holder {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 35px;
  height: 35px;
}

.spinner .path {
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.feedback-fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.no-wrap {
  white-space: nowrap;
}

.font11 {
  font-size: 11px !important;
}

.font14 {
  font-size: 14px !important;
}

.font24 {
  font-size: 24px !important;
}

.font20 {
  font-size: 20px !important;
}

.font18 {
  font-size: 18px !important;
}

.overflow-scroll {
  overflow: scroll;
  scrollbar-width: 0;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

.click {
  cursor: pointer;
}

.settings-modal-wrapper {
  color: #484848;
}

.settings-modal-wrapper .user-detail {
  background: #efefef;
  border-radius: 6px;
  width: 464px;
  min-height: 184px;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.settings-modal-wrapper .user-detail h1 {
  font-size: 28px;
}

.settings-modal-wrapper .user-detail img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 1rem;
}

.settings-modal-wrapper p {
  font-size: 14px;
}

.settings-modal-wrapper .btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 1rem 0;
}

.settings-modal-wrapper .newPass-input .ant-form-item-explain.ant-form-item-explain-error {
  display: none;
}

.text-red {
  color: #ff4d4f;
}

.form-error {
  border: #ff4d4f solid 1px !important;
}

.btn {
  min-height: 40px;
  border-radius: 5px;
  padding: 0 1rem;
}

.btn:active,
.btn:focus {
  outline: none !important;
}

button:active,
button:focus {
  outline: none !important;
}

.btn-outline-grey {
  border: 1px solid #999999;
  color: #999999;
}

.btn-blue {
  background: #273df2;
  color: white;
}

.btn-darkblue {
  background: #1064d6;
  color: white;
}

.form-control {
  border: solid 1px #e3e3e3;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
}

.form-controlinput {
  height: 40px;
}

.form-control:focus {
  outline: none !important;
}

.form-control::-webkit-input-placeholder {
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  font-size: 14px;
}

.form-control::-ms-input-placeholder {
  font-size: 14px;
}

.form-control::placeholder {
  font-size: 14px;
}

input:focus {
  outline: none !important;
}

.welcome-modal-wrapper {
  color: #484848;
}

.welcome-modal-wrapper p {
  font-size: 14px;
  font-weight: 300;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.settings-avatar {
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 155px;
  height: 155px;
}

.header-avatar {
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 35px;
  height: 35px;
}

.formGrid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 45% 1fr;
  grid-template-columns: 45% 1fr;
  grid-column-gap: 22px;
}

@media (max-width: 900px) {
  .formGrid {
    display: block;
  }
}

form label {
  font-weight: bold;
}

div.ant-form-item:not(.password-field) .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.dropZone-wrapper {
  border: 2px dotted grey;
  border-radius: 5px;
}

a:hover {
  color: inherit;
}

a:focus,
a:active {
  text-decoration: none;
}

.btn-badge {
  color: white !important;
  width: 88.19px;
  min-height: 33px;
  font-size: 12px;
}

.btn-badge.bg-red-200 {
  background: #eb5757 !important;
}

.btn-badge.bg-green-200 {
  background-color: #1cb4ae !important;
}

.profile-wrapper {
  min-width: 192px;
  max-width: 100%;
}

.account-setting-wrapper.grid-flow-col {
  -ms-grid-columns: 1fr 3fr;
  grid-template-columns: 1fr 3fr;
}

@media (max-width: 700px) {
  .account-setting-wrapper.grid-flow-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}

.p-r {
  position: relative;
}

.img-picker {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #273df2;
  color: white;
  right: 0;
  bottom: 9px;
  border-radius: 50%;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.img-picker input {
  display: none;
}

.save-btn {
  cursor: pointer;
  color: #273df2;
  font-size: 18px;
  font-weight: bold;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.import-wrapper .import-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 80vh;
}

.import-wrapper .import-flex .center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
}

.import-wrapper .import-flex .center h2 {
  font-size: 36px;
}

@media (max-width: 770px) {
  .import-wrapper .import-flex .center h2 {
    font-size: 26px;
  }
}

.import-wrapper .import-flex .center p {
  font-size: 18px;
  padding: 2rem 0;
}

@media (max-width: 770px) {
  .import-wrapper .import-flex .center p {
    font-size: 16px;
  }
}

.import-wrapper .import-flex .center h5 {
  font-size: 24px;
}

@media (max-width: 770px) {
  .import-wrapper .import-flex .center h5 {
    font-size: 18px;
  }
}

.import-wrapper .import-flex .center .import-box {
  background: #f5f5f7;
  border: 2px dashed #273df2;
  border-radius: 10px;
  color: #979797;
  width: 660px;
  max-width: 100%;
  height: 164px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
  margin: auto;
  cursor: pointer;
}

@media (max-width: 700px) {
  .import-wrapper .import-flex .center .import-box {
    width: 100%;
  }
}

.import-wrapper .import-flex .center .sheet-link {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.import-wrapper .import-flex .center .sheet-link a {
  color: #273df2;
  /* text-decoration: underline; */
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: 770px) {
  .import-wrapper .import-flex .center .sheet-link a {
    font-size: 14px;
  }
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.text-blue {
  color: #273df2;
}

.bg-blue {
  background: #2f80ed;
}

.bg-green {
  background-color: #86db86 !important;
}

.bg-deleting {
  background-color: #e6dbdb !important;
}

.text-grey {
  color: #999999;
}

.imported-recipient-table p {
  font-weight: 300;
  font-size: 14px;
}

.imported-recipient-table .btn-remove {
  background: #f45b5b;
  border-radius: 1.8px;
  color: white;
  width: 83.08px;
  height: 33px;
}

.imported-recipient-table .btn-add {
  background: #263238;
  border-radius: 3px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.imported-recipient-table .list-wrapper {
  height: 63px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 1rem;
}

.imported-recipient-table .list-wrapper.bg-white {
  background: #ffffff;
  border-radius: 5px;
}

.imported-recipient-table .list-wrapper h4 {
  font-size: 18px;
}

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.white-spin .ant-spin-dot-spin .ant-spin-dot-item {
  background: white !important;
}

.empty-import {
  text-align: center;
  font-size: 18px;
  margin-top: 4rem;
}

.empty-import img,
.empty-import svg {
  margin: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.item-to-copy-box {
  width: 80%;
  overflow: scroll;
  scrollbar-width: 0;
}

.item-to-copy-box::-webkit-scrollbar {
  display: none;
}

.total-count {
  height: 25px;
  width: 25px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 770px) {
  .sm-max-flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .sm-d-none {
    display: none;
  }
  .sm-p-5 {
    padding: 1.3rem !important;
  }
  .sm-w-100 {
    width: 100%;
  }
  .sm-flex-col-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.btn-sm {
  min-height: 31px;
}

.font-weight-bold {
  font-weight: bold;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  min-height: 213px;
}

.text-green {
  color: #86db86 !important;
}

.enter-details-wrapper h2 {
  font-size: 22px;
  color: #3d4160;
  font-weight: bold;
}

.enter-details-wrapper .form-wrapper {
  width: 73%;
  margin: auto;
  margin-top: 7vh;
  background: white;
  padding: 1.5rem 2rem 1.5rem;
  border-radius: 8px;
}

.enter-details-wrapper .form-wrapper .img {
  width: 77px;
  height: 102px;
  -o-object-fit: cover;
  object-fit: cover;
}

.enter-details-wrapper .btn-outline-green {
  background-color: transparent;
  border: 2px solid #daefda;
  line-height: 38px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #86db86;
}

.enter-details-wrapper .btn-green {
  background-color: #86db86;
  border: 2px solid #daefda;
  line-height: 38px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: white;
}

.preview-modal-wrapper {
  height: 450px;
  overflow: scroll;
  width: 100%;
}

canvas {
  position: absolute;
  top: -1000%;
}

.email-img {
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100px;
}

@media (max-width: 1000px) {
  .web-builder-wrapper {
    overflow: scroll;
  }
}

.interval-wrapper input {
  width: 40px;
  border-bottom: solid 1px #4848;
  padding-bottom: 0;
  height: 30px;
}

.interval-wrapper select {
  width: 172px;
  border: none;
}

.interval-wrapper + div button {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.received-mails-wrapper {
  background: white;
  border-radius: 5px;
  padding: 20px;
}

.received-mails-wrapper .number {
  font-weight: bold;
  font-size: 15px;
  background: #273df2;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.received-mails-wrapper .received-mails-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  padding: 10px;
  color: #202124;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  background: rgba(255, 255, 255, 0.902);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: solid 1px #b3b5b83d;
  border-radius: 5px;
}

.received-mails-wrapper .received-mails-card:hover {
  background: rgba(242, 245, 245, 0.8);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
}

.received-mails-wrapper .received-mails-card .subject,
.received-mails-wrapper .received-mails-card .date {
  font-weight: 700;
  white-space: nowrap;
  color: #202124;
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.received-mails-wrapper .received-mails-card .date {
  font-size: 12px;
  width: 10%;
}

.received-mails-wrapper .received-mails-card .body {
  color: #5f6368;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.received-mails-wrapper .received-mails-card .body p {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 990px) {
  .received-mails-wrapper .received-mails-card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .received-mails-wrapper .received-mails-card .subject {
    width: 100%;
  }
  .received-mails-wrapper .received-mails-card .body {
    width: 80%;
    padding: 0;
    padding-right: 10px;
  }
  .received-mails-wrapper .received-mails-card .date {
    width: 20%;
  }
}

@media (max-width: 490px) {
  .received-mails-wrapper .received-mails-card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .received-mails-wrapper .received-mails-card .subject,
  .received-mails-wrapper .received-mails-card .body,
  .received-mails-wrapper .received-mails-card .date {
    padding: 0;
    width: 100%;
  }
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination .page-link {
  color: #273df2;
}

.pagination .page-item.active .page-link {
  background-color: #273df2;
  border-color: #273df2;
}

@media (max-width: 800px) {
  .each-campaign-card .btn {
    padding: 0 0.5rem !important;
    width: 71px;
    min-height: 25px;
  }
}

.popup-div {
  visibility: hidden;
  min-width: 216px;
  background-color: #7c8ba0ed;
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 1;
  bottom: 81%;
  right: -120px;
}

.popup-div.show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.popup-div::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 37%;
  border-width: 6px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.popup-div .social-icons {
  background-color: #fff;
  margin-right: 10px;
  -webkit-box-shadow: 0 0 5px 0 #ccc;
  box-shadow: 0 0 5px 0 #ccc;
  width: 40px;
  height: 40px;
  line-height: 45px;
  border-radius: 50px;
  font-size: 18px;
  color: #273df2;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .popup-div .social-icons {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 800px) {
  .popup-div {
    min-width: 50px;
    right: -25px;
  }
  .popup-div .flex {
    display: block !important;
  }
}

.btn-outline-blue {
  border: 1px solid #011a32;
  color: #011a32;
}

.admin-join-page-wrapper {
  background-color: #f5f5f7;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3rem;
  padding-top: 4rem;
}

.admin-join-page-wrapper .left {
  width: 65%;
  margin-bottom: 1.5rem;
}

.admin-join-page-wrapper .left .inner {
  width: 590px;
  max-width: 100%;
  margin: auto;
}

.admin-join-page-wrapper .left .inner h4 {
  font-size: 1.4rem;
}

.admin-join-page-wrapper .left .inner .edit-join-page-wrapper {
  padding: 1.5rem;
  width: 100%;
  min-height: 452px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.admin-join-page-wrapper .left .inner .edit-join-page-wrapper .inner {
  min-width: 300px;
  width: 350px;
  max-width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.admin-join-page-wrapper .left .inner .edit-join-page-wrapper .inner .edit-logo{
  resize: both;
  overflow: hidden;
  line-height: 0;
  width: 140px;
  height: 104px;
}

.admin-join-page-wrapper .left .inner .edit-join-page-wrapper img {
  width: 100%;
    height: 100%;
  /* width: 140px;
    height: 104px;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: contain; */
 /*  resize: both;
    overflow: scroll;
    border: 1px solid #ccc; */
}

.admin-join-page-wrapper .left .inner .edit-join-page-wrapper h2 {
  font-size: 1.8rem;
}

.admin-join-page-wrapper .right {
  width: 35%;
}

.admin-join-page-wrapper .right .inner {
  width: 340px;
  max-width: 100%;
  margin-left: auto;
}

.admin-join-page-wrapper .right .inner .btn {
  width: 154px;
}

.admin-join-page-wrapper .right .inner .ip-box .import-label,
.admin-join-page-wrapper .right .inner .ip-box .empty-click {
  cursor: pointer;
  width: 298px;
  max-width: 100%;
  height: 269px;
  background: #f8f7f7;
  border-radius: 4px;
  text-align: center;
  color: #52677a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.admin-join-page-wrapper .right .inner .ip-box .import-label img,
.admin-join-page-wrapper .right .inner .ip-box .empty-click img {
  margin: auto;
  max-width: 100%;
}

.admin-join-page-wrapper .right .inner .ip-box .import-label h4,
.admin-join-page-wrapper .right .inner .ip-box .empty-click h4 {
  font-weight: bold;
  font-size: 1.2rem;
}

.admin-join-page-wrapper .right .inner .ip-box .import-label span,
.admin-join-page-wrapper .right .inner .ip-box .empty-click span {
  font-size: 14px;
}

@media (max-width: 1012px) {
  .admin-join-page-wrapper {
    display: block;
  }
  .admin-join-page-wrapper .left {
    width: 90%;
  }
  .admin-join-page-wrapper .left .inner {
    margin: 0;
  }
  .admin-join-page-wrapper .right {
    width: 80%;
  }
  .admin-join-page-wrapper .right .inner {
    margin: 0;
  }
}

@media (max-width: 800px) {
  .admin-join-page-wrapper {
    padding: 2rem;
  }
  .admin-join-page-wrapper .left,
  .admin-join-page-wrapper .right {
    width: 100%;
  }
  .admin-join-page-wrapper .edit-join-page-wrapper .inner {
    min-width: 0 !important;
  }
}

@media (max-width: 300px) {
  .admin-join-page-wrapper {
    padding: 1rem;
  }
}

.w-100 {
  width: 100%;
}

.custom-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 15px;
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #273df2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-switch .slider:before {
  position: absolute;
  content: '';
  height: 23px;
  width: 23px;
  left: 0px;
  bottom: -4px;
  background-color: white;
  border: 2px solid #666666;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-switch input:checked + .slider {
  background-color: #009d00;
}

.custom-switch input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #009d00;
  box-shadow: 0 0 1px #009d00;
}

.custom-switch input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

.custom-switch .slider.round {
  border-radius: 34px;
}

.custom-switch .slider.round:before {
  border-radius: 50%;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.centerLoadingPage {
  text-align: center;
  height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/*# sourceMappingURL=App.css.map */
#hubspot-messages-iframe-container {
  z-index: 1;
}
