@import './variables.scss';

.ant-table-thead>tr>th {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #8e8e8e;
  font-family: 'Inter';
}

.ant-table-row {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1c1c1c;
  font-family: 'Inter';
}

.ghostBtn {
  display: flex;
  gap: .1rem;
}