@import './variables.scss';

:root {
  --dashbsideBarWidth: 230px;
  --sideBarWidthActive: 280px;
}

.dashboardContainer {
  //height: 90vh;
  width: 100%;
  display: flex;

  .sidebarContainer {
    .padLR {
      padding: 0px 8px;
    }

    .padT {
      padding-top: 8px;
    }
    .margLR {
      margin: 10px 10px 0px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    height: 100%;
    width: var(--dashbsideBarWidth);
    background: var(--black-color);
    padding: 0px;
    padding-top:12px;
    transition: all 0.5s ease;
    .ant-card-body {
      padding: 10px;
    }
    .ant-alert-error {
      background-color: #fff3e5;
      border: none;
      margin: 0rem 0px 2rem;
    }
    .ant-alert-message {
      color: #383838;
      font-weight: 500;
      font-size: 14px;
    }
    .ant-alert-description {
      color: #383838;
      font-weight: 400;
      font-size: 12px;
    }

    .totalAnalyticsTitle {
      color: #1c1c1c;
      margin-left: 10px;
    }
    .totalAnalytics {
      display: flex;
      justify-content: space-between;
      padding: 1rem 10px 0rem;

      p {
        margin-top: 5px;
        color: #383838;
      }
      h6 {
        color: #1c1c1c;
      }
    }
    .totalAnalyticsFooter {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #c7c7c7;
      font-family: 'Inter';
      margin-top: 2rem;
    }
    .advertSection {
      position: absolute;
      bottom: 0;
      width: 24.5%;
    }
    .advert {
      background-color: #273df2;
      position: relative;
      height: 200px;
      border-radius: 5px;
    }
    .advert-inner {
      background-color: rgba(255, 255, 255, 0.5);
      width: 93%;
      height: 40px;
      border-radius: 5px;
      position: absolute;
      bottom: 0;
      margin-bottom: 1rem;
    }

    &.sidebarContainer {
      @media (min-width: 992px) {
        width: 25%;
        & ~ .dashboardContentContainer {
          width: calc(100% - var(--sideBarWidthActive));
          right: var(--sideBarWidthActive);
        }
      }
    }
  }

  .dashboardContentContainer {
    height: 100%;
    width: 75%;
    overflow-y: auto;
    padding: 20px 0px 20px 20px;
    transition: all 0.5s ease;
    border-right: 1px solid #f2f2f2;

    .completeProfileCTA{
      margin-right: 23px;
      margin-bottom: 30px;
      background: linear-gradient(white, white) padding-box, linear-gradient(to left, hsl(241, 37%, 94%), hsl(241, 37%, 70%)) border-box;
      border-radius: 8px;
      border: 1px solid transparent;

      display: flex;
      align-items: center;
      gap: 13px;
      padding: 20px 16px;

      .complete-profile-right{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 60px;
        margin-right: 32px;

        .complete-profile-header{
          color: #000;
          font-family: 'Paralucent-Medium';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 144.444% */
        }

        .complete-profile-description{
          color: $fade555;
          font-family: 'Paralucent-Light';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
        }

        .complete-profile-button-container{
          #btn--type-primary_lg.complete-profile-button{
            width: 213px;
            font-family: 'Paralucent-Medium';
            font-size: 14px;
          }
        }
      }
    }

    canvas {
      position: static;
      top: 0%;
    }

    .heading {
      margin-bottom: 10px;
      border-bottom: 1px solid#f2f2f2;
    }

    .dashboard-create-new-container {
      display: none;
    }

    .dashboard-modal-mobile {
      display: none;
    }

    .userAnalytics {
      display: flex;
      padding-top: 20px;
      color: #1c1c1c;
      .userAnaly {
        margin-right: 1rem;
        .userAnalyCard {
          width: 150px;
          height: 65px;
          padding: 5px;

          .ant-card-body {
            padding: 5px 10px;

            .typography--variant-captionMedium {
              color: $descriptionTextColor;
            }
          }
        }
      }

      .contactUptrend {
        margin: 0;
        background-color: rgba(55, 179, 74, 0.05);
        padding: 0 0.25rem;
        .contactUptrendCount {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          font-family: 'Inter';
          color: #37b34a;
          font-style: normal;
        }
        .contactUptrendIcon {
          color: #37b34a;
          transform: rotate(45deg);
        }
      }
    }

    .performance-header {
      .view-analytics-dropdown-container {
        display: none;
      }
    }

    .performanceSection {
      display: flex;
      justify-content: space-between;
    }
    .openDeliverySection {
      width: 48%;
      height: 100%;
    }

    .clickRateSection {
      width: 48%;
      height: 100%;
    }

    .performanceTitle {
      color: #1c1c1c;
    }
    .performanceSwitch {
      background: #f9f9f9;
      border-radius: 25px;
      padding: 0.3rem 0.3rem;
      margin-right: 0.5rem;
      height: 42px;
    }
    .performanceRatings {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
    }

    .toggleSwitchActive {
      background-color: #fff;
      border-radius: 25px;
      color: #383838;
    }

    .switchText {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #383838;
      font-family: 'Paralucent-Medium';
    }

    .ant-radio-button-wrapper {
      position: relative;
      display: inherit;
      height: 32px;
      margin: 0;
      padding: 0 15px;
      color: #383838;
      background: 0;
      font-size: 14px;
      line-height: 30px;
      border: none;
      border-top-width: 0;
      border-left-width: 0;
      cursor: pointer;
      transition: color 0.3s, background 0.3s;
      border-radius: 25px;
      box-shadow: none;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      position: absolute;
      top: -1px;
      left: -1px;
      display: none;
      box-sizing: content-box;
      width: 1px;
      height: 100%;
      padding: 1px 0;
      background-color: #d9d9d9;
      transition: background-color 0.3s;
      content: '';
      box-shadow: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      color: #383838;
      border-color: #fff;
      box-shadow: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      z-index: 1;
      color: #383838;
      background: #fff;
      box-shadow: none;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
      color: #383838;
      border-color: #fff;
      box-shadow: none;
    }

    .dashboardEmailCampaignSection {
      color: #1c1c1c;
      margin: 1.5rem 7px;
    }

    .emailCampBtn {
      color: #aaaaaa;
      font-size: 12px;
      border: 1px solid #f2f2f2;
      width: 46px;
      height: 24px;
      border-radius: 0px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      font-family: 'Paralucent-Medium';
      &:hover {
        cursor: pointer;
        background-color: rgba(39, 61, 242, 0.1);
        color: #273df2;
        border: 1px solid #273df2;
      }
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      position: relative;
      top: 50%;
      right: 0;
      width: 1px;
      height: 1rem;
      background-color: rgba(0, 0, 0, 0.06);
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      position: relative;
      padding: 10px 10px;
      overflow-wrap: break-word;
    }

    .ant-table-thead > tr > th,
    .ant-table-column-sorters {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #717171;
      background: transparent;
    }
    .ant-table-row {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #555555;
    }
  }
  @media (max-width: 600px) {
    display: block;

    .sidebarContainer {
      width: 100%;
      .advertSection {
        position: relative;
        bottom: 0;
        width: 100%;
      }
    }
    .dashboardContentContainer {
      width: 100%;
      .userAnalytics {
        display: block;
        .userAnaly {
          margin-bottom: 1rem;
          .userAnalyCard {
            width: 200px;
            height: 75px;
          }
        }
      }
      .performanceSection {
        display: block;
      }
      .openDeliverySection {
        width: 100%;
        height: 300px;
        padding-right: 2rem;
      }

      .clickRateSection {
        width: 100%;
        height: 300px;
        padding-right: 2rem;
        margin-top: 1rem;
      }
      .performanceRatings {
        display: block;
      }
      .performanceSwitch {
        background: #f9f9f9;
        width: 170px;
        text-align: center;
        border-radius: 25px;
        padding: 0.3rem 0.6rem;
        margin: 1rem 1rem 0rem 0rem;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    display: block;

    .sidebarContainer {
      width: 100%;
      .advertSection {
        position: relative;
        bottom: 0;
        width: 100%;
      }
    }
    .dashboardContentContainer {
      width: 100%;
      .userAnalytics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .userAnaly {
          margin-bottom: 1rem;
          .userAnalyCard {
            width: 250px;
            height: 75px;
          }
        }
      }
      .performanceSection {
        display: block;
      }
      .openDeliverySection {
        width: 100%;
        height: 400px;
        padding-right: 1rem;
      }

      .clickRateSection {
        width: 100%;
        height: 400px;
        padding-right: 1rem;
      }
      .performanceRatings {
        display: flex;
      }
      .performanceSwitch {
        background: #f9f9f9;
        width: 170px;
        text-align: center;
        border-radius: 25px;
        padding: 0.3rem 0.6rem;
        margin: 0rem 1rem 1rem 0rem;
      }
    }
  }
  @media (min-width: 999px) and (max-width: 1024px) {
    .dashboardContentContainer {
      .userAnalytics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .userAnaly {
          margin-bottom: 1rem;
          .userAnalyCard {
            width: 250px;
            height: 75px;
          }
        }
      }
      .performanceSection {
        display: flex;
      }
      .openDeliverySection {
        width: 48%;
        height: 300px;
        // padding-right: 2rem;
      }

      .clickRateSection {
        width: 48%;
        height: 300px;
        // padding-right: 2rem;
      }
      .performanceRatings {
        display: block;
      }
      .performanceSwitch {
        background: #f9f9f9;
        width: 170px;
        text-align: center;
        border-radius: 25px;
        padding: 0.3rem 0.6rem;
        margin: 1rem 1rem 0rem 0rem;
      }
    }
  }

  // Mobile responsiveness for dashboard 07/2023
  @media (max-width: 500px) {
    .sidebarContainer {
      display: none;
    }

    .dashboardContentContainer {
      padding: 30px 20px 40px;

      .dashboard-modal-mobile {
        display: block;
        margin: 16px 0;

        .ant-alert {
          padding: 15px;
        }
      }

      .heading {
        border: none;
      }

      .dashboard-create-new-container {
        display: block;

        .maildrip_btn {
          margin-left: 0;
        }

        .btn-create {
          margin: 20px 0;
        }
      }

      .userAnalytics {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        gap: 8px;
        padding-top: 0;

        .userAnaly {
          margin: 0;
          .userAnalyCard {
            width: 100%;
            height: 69px;
            padding-right: 10px;

            .ant-card-body {
              .typography--variant--h4Medium {
                font-size: 18px;
              }
            }
          }
        }
      }

      .performance-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        position: relative;

        .view-analytics-dropdown-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;

          .view-analytics-dropdown {
            display: none;
            position: absolute;
            top: 40px;
            right: 0;
            z-index: 5;
            width: 280px;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.05);
            padding: 20px 13px;

            .totalAnalyticsTitle {
              margin-bottom: 18px;
            }

            .ant-card {
              border: none;
              padding: 0;

              .ant-card-body {
                padding: 0;

                .totalAnalytics {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 12px 0;
                  border-bottom: 0.5px solid $lighterBorder;
                }

                .totalAnalytics:last-child {
                  border: none;
                }

                .totalAnalyticsFooter {
                  margin-top: 28px;
                  font-size: 12px;
                  color: $seeSeven;
                }
              }
            }
          }
          .text {
            color: $primaryColor;
            font-family: 'Paralucent-Light';
            font-size: 14px;
          }
        }

        .view-analytics-dropdown-container:hover {
          .view-analytics-dropdown {
            display: block;
          }
        }
      }

      .performanceSection {
        .performanceRatings {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 10px;

          .performance-rates {
            display: flex;
            gap: 19px;

            .mr-4 {
              margin-right: 0;
            }

            .typography--variant-captionMedium {
              font-size: 10px;
            }
            .typography--variant-pMedium {
              font-size: 14px;
            }
          }
        }

        .performanceSwitch {
          margin: 0;
          // height: 30px;
          width: 130px;
          padding: 4.426px 7.082px;
          display: flex;
          align-items: center;

          .ant-radio-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 80%;

            .ant-radio-button-wrapper {
              height: fit-content;
              border-radius: 25px;
              padding: 2px 10px;
              margin: 0;
              font-size: 12px;
            }
          }
        }

        .openDeliverySection,
        .clickRateSection {
          padding: 12px;
          height: fit-content;
          border-radius: 4px;
          border: 1px solid #f1f1f1;
        }
      }

      .dbemails {
        .ant-table-wrapper {
          .ant-table-content {
            .ant-table-thead {
              tr {
                display: grid;
                grid-template-columns: 0.5fr 0.5fr 0.2fr;
                th:nth-child(3),
                th:nth-child(4),
                th:nth-child(5) {
                  display: none;
                }
              }
            }
            .ant-table-tbody {
              tr {
                display: grid;
                grid-template-columns: 0.5fr 0.5fr 0.2fr;

                td:nth-child(2) {
                  padding-left: 19px;
                }
                td:nth-child(3),
                td:nth-child(4),
                td:nth-child(5) {
                  display: none;
                }
              }

              .ant-table-placeholder {
                grid-template-columns: 1fr;
              }
            }
          }
        }
      }
    }
  }
}

.complete-profile-modal{
  .ant-modal-close{
    display: none;
  }
  .ant-modal-body{
    padding: 32px;
    position: relative;

    .close-icon-container{
      position: absolute;
      top: 0;
      right: 0;
      margin: 16px ;
      cursor: pointer;
    }

    .title-container{
      max-width: 325px;
      .title{
        color: #000;
        font-size: 16px;
      }
    }

    .complete-profile-form-container{
      margin-top: 32px;

      form{
        label{
          color: $fade555;
          font-family: 'Paralucent-Light';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        input{
          font-family: 'Paralucent-Light';
        }
      }

      .complete-profile-button-container{
        width: 100%;
        margin-top: 56px;
        margin-bottom: 16px;
        #btn--type-primary_lg.complete-profile-button{
          width: 100%;
          color: #F7F7F7;
          
          .typography--variant-h6Medium{
            font-size: 14px;
          }
        }
      }
    }

    .profile-completed-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 34px;
      margin-top: 32px;

      .completed-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8px;
        
        .typography--variant-h6Medium{
          font-size: 18px;
        }

        .typography--variant-pLight{
          font-size: 14px;
          color: $descriptionTextColor;
          width: 294px;
        }
      }
      
      .completed-button-container{
        margin-top: 38px;
        margin-bottom: 18px;
      }
    }
  }

}

.low-email-credits-notf{
  margin-top: 7px;
  padding: 15px 24px 15px 32px;
  background: #FFECB9;
  
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  font-family: 'Paralucent-Light';

  h3.warning-heading{
    color: #DB2222;
    font-size: 12px;
    font-weight: 400;
  }

  p.warning-text{
    color:  $fade5;
    font-size: 12px;
    line-height: 17px;
  }

  .top-up{
    color: $primaryColor;
    font-family: 'Paralucent-Medium';
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-decoration-line: underline !important;
    margin: 0;
    margin-left: 16px;
  }

  .close-icon{
    position: absolute;
    top: translateX(50%);
    right: 24px;
  }
}

.promo-container{
  width: calc(100% - 20px);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e2e8f0;
  border-radius: 4px;

  & > div:first-child{
    display: flex;
    gap: 24px;
  }
}

.promo-text-container{
  width: 400px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    // line-height: 1.5;
}

.promo-text-header{
  font-family: Paralucent-Medium;
  color: #000;
  font-size: 18px;
  margin-bottom: 1rem;
}

.promo-text{
  font-family: Paralucent-Light;
  font-size: 1rem;
  line-height: 1.5rem;
  line-clamp: 3;
  margin: 0;
  // word-spacing: .5rem;
}

.promo-button{
  background-color: #273DF2;
  width: 312px;
  padding: 8px 26px;
  border-radius: 4px;
  color: #F7F7F7;
  font-family: Paralucent-Medium;
  font-size: 14px;
  font-weight: 400;
}

.promo-button:hover{
  color: #273DF2;
  border: 1px solid #273DF2;
  background-color: #fff;
}