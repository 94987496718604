@import 'styles/variables.scss';

.pinbox_outer {
  max-height: 380px;
  text-align: center;
  color: #383838;

  .pinbox {
    margin-top: 50px;
    padding: 20px;
  }

  .pinbox__header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 22px;
  }
  .pinbox_cancel_button {
    margin-top: 60px;
    font-weight: 600;
  }

  .pin_inputs_wrapper {
    display: flex;
    justify-content: center;
  }
}
