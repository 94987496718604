.createEmailModal {
  width: 50%;
  padding: 10px;
  margin: auto;
  margin-top: 40px;
}
.hero {
  display: flex;
  justify-content: space-between;
  border: none;
  gap: 10;
}

.section .section-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #effbf0;
  height: 285px;
  width: 254px;
  margin-bottom: 1.5rem;
}

.section-sub .section-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f5fe;
  height: 285px;
  width: 254px;
  margin-bottom: 1.5rem;
}
.section-sub .section-image .imgCon {
  height: 132px;
  width: 206.39999389648438px;
}

.email-builder {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.select-button {
  margin: auto;
}

.select-button .btn-campaign {
  border: 1px solid rgb(241, 231, 231);
  .campaign-text {
    color: #555555;
  }
}

.select-button .email-text {
  border: 1px solid rgb(241, 231, 231);

  .mail-text {
    color: #555555;
  }
}

.text-btn {
  .text-paragraph {
    color: #555555;
  }
}

.text-button {
  .paragraph-text {
    color: #555555;
  }
}

@media (max-width: 500px) {
  .createEmailModal {
    width: 100%;

    .hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;

      .ml-6 {
        margin: 0;
      }

      .section-sub {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;

        .section-image {
          margin-right: 0;

          .imgCon {
            margin: auto;
          }
        }
      }
    }

    .email-builder {
      max-width: 254px;
    }
  }
}
