.payBtnWrapper {
  button {
    background: #273df2;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: #f7f7f7;
    display: grid;
    place-content: center;
    margin-top: 10px;
    &:disabled {
      background-color: #273df26b;
    }
  }
}
