@import 'styles/variables.scss';

.otpbox_outer {
  max-height: 380px;
  text-align: center;
  color: #383838;

  .otpbox {
    margin-top: 50px;
    padding: 20px;
  }
  .otpbox__header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 50px;
  }

  .otp__inner_wrapper {
    display: flex;
    align-items: baseline;
    gap: 10px;
    input {
      text-align: center;
      border: 1px solid $shade1;
      box-sizing: border-box;
      border-radius: 4px;
      height: 48px;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      color: #000;
      margin-bottom: 15px;
      padding: 15px 18px;
      background: #ffffff;
      flex: 0.6;
    }

    .payBtnWrapper {
      flex: 0.4;
    }
  }
}
