@import './variables.scss';
.emmaccen {
  .resetPasswordContainer {
    .carousel {
      height: 100vh !important;
    }
    .formContent {
      padding: 10px 0px;
      & > div {
        height: 100%;
      }
    }

    .resetPwdTitle {
      @media (max-width: 500px) {
        text-align: center;
        padding: 30px 0;
      }
    }

    .margin-t-b {
      display: none;
    }

    .lets-get-your-account-back {
      @media (max-width: 500px) {
        font-size: 18px;
        font-weight: 400;
        color: #374049;
        font-family: 'Paralucent-Medium';
      }
    }

    .title {
      @media (max-width: 500px) {
        color: #374049;
        font-family: 'Paralucent-Bold';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
