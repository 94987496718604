@import 'styles/variables.scss';

.paymentPageContainer {
  background: #ffffff;
  box-shadow: 0px 6px 38.4354px rgb(0 0 0 / 5%);
  border-radius: 8px;
  padding: 5px 30px;
  margin: 8px 10px 70px 10px;
}

.payment_container {
  margin-bottom: 60px;
  h2 {
    font-size: 24px;
    color: #45505b;
    font-weight: 600;
    margin-bottom: 32px;
  }
  h4 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: #000000;
  }
  hr {
    margin-bottom: 20px;
  }

  .payment_container__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  .divider_wrapper {
    width: 40%;
    margin-top: 45px;
  }
  .planFormWrapper {
    display: flex;
    gap: 150px;
    .switch {
      background-color: $primaryColor;
      border-radius: 20px;
      margin-right: 10px;
    }
    .planWrapper {
      flex: 1;
      max-width: 450px;
      .billingCycle {
        font-weight: 500;
        font-size: 18px;
        color: #717171;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        p {
          margin-right: 10px;
        }

        .promotion {
          font-weight: 400;
          font-size: 14px;
          color: #fea800;
          margin: 0;
        }
      }

      .plans {
        .planType {
          border: 2px solid #c7c7c7;
          border-radius: 10px;
          padding: 20px;
          margin-bottom: 10px;
          .headerIcon {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 12px;
            .promotion {
              display: block;
              font-weight: 500;
              color: #fea800;
            }
            h5 {
              font-weight: 600;
              font-size: 14px;
              color: #8e8e8e;
              text-transform: uppercase;
            }
            svg {
              display: none;
            }
          }

          .creditsPriceWrapper {
            .credits {
              gap: 5px;
            }
            .credits,
            .price {
              font-weight: bold;
              font-size: 24px;
              line-height: 64.52%;
              letter-spacing: -0.055em;
              color: #000000;
              display: flex;
              align-items: center;

              .emailCredits {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #717171;
                letter-spacing: 0;
              }
              .cycle {
                font-weight: 600;
                font-size: 10px;
                color: #000000;
              }
            }
          }
        }
        .comparePlans {
          display: flex;
          justify-content: flex-end;
          font-weight: 500;
          font-size: 14px;
          color: $primaryColor;
        }
      }

      .paygWrapper {
        .paygHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          margin-bottom: 15px;

          h5 {
            font-size: 18px;
            color: #1c1c1c;
          }
          .autoRenew {
            p {
              font-size: 14px;
              color: #717171;
              margin-right: 10px;
            }
          }
        }
        .payg {
          padding: 20px;
          border: 2px solid #8e8e8e;
          border-radius: 5px;
          p {
            font-weight: 500;
            font-size: 12px;
            color: #717171;
            margin-bottom: 5px;
          }
          .selectQuantityWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .selectQuantity {
              h6 {
                font-weight: 500;
                font-size: 14px;
                color: #1c1c1c;
                margin-bottom: 10px;
              }
              input {
                border: none;
                border-bottom: 1.5px solid rgba(39, 61, 242, 0.49);
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #000;
                width: 100px;
                padding: 3px 5px;
              }
              input::placeholder {
                color: #f2f2f2;
              }
            }
            .price {
              background: rgba(212, 216, 252, 0.2);
              border-radius: 5px;
              height: 60px;
              width: 100px;
              display: grid;
              place-content: center;
              p {
                font-weight: 600;
                font-size: 24px;
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
  .formWrapper {
    flex: 1;
    max-width: 450px;
    h2 {
      margin-top: 10px;
      color: #383838;
      margin-bottom: 24px;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      color: #8e8e8e;
      margin-bottom: 20px;
    }
  }
  .selectedPlan {
    border: 2px solid $primaryColor !important;
    .promotion {
      display: none !important;
    }
    svg {
      display: block !important;
    }
  }
  .selected_card__border {
    border: 2px solid $primaryColor !important;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    color: $fade4;
    border-top-color: $fade4;
  }
  // *****************************************
  // GLOBAL MEDIA QUERY
  // *****************************************

  // *****************************************
  //  Max Width - 1100px
  // *****************************************
  @media (max-width: 1100px) {
  }
  // *****************************************
  //  Max Width - 1030px
  // *****************************************
  @media (max-width: 1030px) {
    .payment_container__grid {
      grid-template-columns: 1fr;
    }
    .payment_container__plans {
      max-width: 500px;
      margin-bottom: 40px;
    }
    .planFormWrapper {
      gap: 60px !important;
    }
    .formWrapper {
      margin-bottom: 60px !important;
    }
  }

  // *****************************************
  // Max Width - 900px
  // *****************************************
  @media (max-width: 900px) {
    .planFormWrapper {
      flex-direction: column;
    }
    .credits {
      flex-wrap: wrap !important;
    }
  }

  // *****************************************
  // Max Width - 425px
  // *****************************************
  @media (max-width: 425px) {
    .planFormWrapper {
      flex-direction: column;
      .billingCycle {
        font-size: 16px !important;
      }
      .price,
      .credits {
        font-size: 20px !important;
      }
    }
    .paygHeader h5 {
      font-size: 16px !important;
    }
    .divider {
      width: 90% !important;
      p {
        font-size: 16px !important;
      }
    }
  }
}

.paymentPageContainer {
  .pageHeader {
    font-size: 24px;
    color: $fade2;
    font-weight: 600;
  }
  .desc {
    font-weight: 600;
    color: #000;
    font-weight: 600;
    font-size: 20px;
    margin-top: 30px;
  }

  .cardSection {
    .card {
      background: #fdfdff;
      box-shadow: 0px 41.7776px 38.4354px rgba(0, 0, 0, 0.079074), 0px 22.3363px 20.5494px rgba(0, 0, 0, 0.0655718);
      border-radius: 8px;
      min-height: 300px;
      width: 100%;

      .newCardInputContainer {
        .title {
          font-weight: 600;
          font-size: 16px;
          color: $fade4;
        }
      }
    }
  }
}
