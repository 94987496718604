@import './variables.scss';

.whats-new_block-contain {
  margin-bottom: 2rem;
  & > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: $fade5;
    margin-bottom: 1rem;
  }
  & > div {
    margin-bottom: 1rem;

    & > p {
      color: $fade555;
      font-size: 16px;
      line-height: 28px;
    }
    & > ul {
      margin-left: 1.5rem;
    }
  }
  & > p {
    margin-bottom: 1rem;
    color: $fade555;
    font-size: 16px;
    line-height: 28px;
  }
}
