@import './variables.scss';

:root {
  --sideBarWidth: 78px;
  --sideBarWidthActive: 200px;
}

.contactPageContainer {
  position: relative;
  min-height: 100vh;
  width: 100%;
  //   overflow: hidden;

  .sidebar {
    .padLR {
      padding: 0px 24px;
    }
    .padT {
      padding-top: 20px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    border-right: 1px solid #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--sideBarWidth);
    background: var(--black-color);
    // padding: 20px 14px 20px 14px;
    padding: 0px;
    z-index: 99;
    transition: all 0.5s ease;

    .addGroup {
      margin-top: 0px;
      opacity: 0;
      p {
        font-size: 18px;
        font-weight: 700;
        color: $fade555;
      }
    }
    .addContact,
    .addGroup img {
      background: rgba(32, 151, 246, 0.04);
      border-radius: 5px;
      height: 25px;
      width: 25px;
    }
    .contactListMenu ul li {
      padding: 10px 20px;
    }

    &.active {
      @media (min-width: 700px) {
        width: var(--sideBarWidthActive);
        & ~ .contentContainer {
          width: calc(100% - var(--sideBarWidthActive));
          left: var(--sideBarWidthActive);
        }
        .addGroup {
          opacity: 1;
        }
        .addContact {
          opacity: 0;
        }
        & ul {
          li {
            margin-bottom: 0;
            .listMenuContent {
              transition: 0s;
              // display: block;
              pointer-events: auto;
            }
            .mobileMenuItem {
              // opacity: 0;
              display: none;
            }
          }
        }
      }
    }

    & ul {
      margin-top: 40px;
      li {
        position: relative;
        // height: 50px;
        margin-bottom: 0;
        width: 100%;
        list-style: none;
        .listMenuContent {
          display: none;
          pointer-events: none;
          transition: all 0.3s ease;
          .contact_title {
            color: #1c1c1c;
          }
          .total_contacts {
            color: #717171;
          }
          p {
            font-size: 16px;
            font-weight: 600;
            color: $fade555;
          }
          .faded {
            color: $fade3;
            font-weight: 500;
            font-size: 14px;
          }
        }
        .mobileMenuItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .linkName {
            background-color: #f3f4fc;
            // padding: 5px;
            text-align: center;
            width: 35px;
            height: 35px;
            border-radius: 10px;
            color: $primaryColor;
            font-weight: 700;
          }
        }
      }
    }
  }
  .contentContainer {
    height: 100%;
    margin-left: auto;
    left: var(--sideBarWidth);
    padding: 20px;
    padding-top: 0;
    transition: all 0.5s ease;
    .heading {
      margin-bottom: 10px;
      h3 {
        font-size: 18px;
        color: $fade555;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .contentEditable {
        font-size: 14px;
        color: $fade3;
        font-weight: 500;
      }
    }
    .actionTab {
      .searchInput {
        background-color: #f7f7f7;
        border-radius: 4px;
        color: #aaaaaa;
        & * {
          background-color: $lighterBorder;
        }
      }
      .actions {
        button {
          min-width: 130px;
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          &:hover {
            color: #fff;
          }
        }
        .ghostBtn {
          border: 1px solid $shade1;
          &:hover {
            color: #000;
          }
        }
      }
      @media (max-width: 970px) {
        .flex-space-btw {
          display: block;
          .actions {
            margin-top: 20px;
            @media (max-width: 650px) {
              .ant-space {
                display: block;
                .ant-btn {
                  min-width: 80%;
                  margin: auto;
                  margin-bottom: 10px;
                  @media (max-width: 450px) {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .contactTableContainer {
      border: 1px solid $lightBorder;
      padding: 10px;
      table {
        .ant-table-thead {
          th {
            color: $fade4;
            font-size: 18px;
            font-weight: 400;
          }
        }
        td {
          color: $fade5;
          font-size: 16px;
          font-weight: 400;
          .tableActionBtns {
            & button {
              border: 2px solid $lightBorder;
              color: $fade4;
              font-size: 12px;
              border-radius: 4px;
              &:first-child {
                margin-right: 15px;
              }
              &:last-child {
                color: #f45b5b;
              }
              &.allcontact-edit_btn {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
  }
  .contact_head_box {
    height: 185px;
    background: rgba(12, 127, 218, 0.05);
    border-radius: 8px;
    h1 {
      font-size: 32px;
      line-height: 44px;
      color: #45505b;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
      color: #555555;
    }
    .link {
      color: #273df2;
      text-decoration: underline;
    }
    .search-input {
      margin-top: 20px;
      border: none;
      width: 100%;
      max-width: 301px;
      height: 35px;
      background: #ffffff;
      border-radius: 4px;
      padding: 8px 15px;
      gap: 11px;
      .anticon {
        color: #8e8e8e;
        width: 13px;
        height: 13px;
      }
    }
  }
  .contact_select {
    border-radius: 4px;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #717171;
    flex-grow: 1;
  }
  .contact_group_btn {
    background: #717171;
    border-radius: 4px;
    padding: 8px 26px;
    gap: 5px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #f7f7f7;
    .anticon svg {
      color: #f7f7f7;
      margin-top: 2px;
      width: 16px;
      height: 16px;
    }
  }
  .contact_group_btn:hover {
    color: #f7f7f7;
  }
  .contact_group_btn:focus {
    color: #f7f7f7;
  }
  .contact_actions {
    .export_btn {
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      align-items: center;
      padding: 8px 14px;
      gap: 5px;
    }
    .export {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #8e8e8e;
    }
  }
  .addNew_btn {
    background: #273df2;
    border-radius: 4px;
    letter-spacing: 0.01em;
    color: #f7f7f7;
    padding: 8px 26px;
    gap: 5px;
  }
  .mobileMenuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .linkName {
      background-color: #f3f4fc;
      text-align: center;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      color: $primaryColor;
      font-weight: 700;
    }
  }
  .contactListMenu ul li {
    padding: 10px 20px;
  }
  h3 {
    line-height: revert !important;
  }
}

// New Contact Page

.newContentContainer {
  height: 100%;
  width: 100%;
  margin-left: auto;
  padding: 20px;
  transition: all 0.5s ease;
  .heading {
    margin-bottom: 10px;
    h3 {
      font-size: 18px;
      color: $fade555;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .contentEditable {
      font-size: 14px;
      color: $fade3;
      font-weight: 500;
    }
  }
  .actionTab {
    margin: 1.5rem 0 2rem 0;
    .searchInput {
      background-color: #f7f7f7;
      border-radius: 4px;
      color: #aaaaaa;
      & * {
        background-color: $lighterBorder;
      }
    }
    .actions {
      button {
        min-width: 130px;
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        &:hover {
          color: #fff;
        }
      }
      .ghostBtn {
        border: 1px solid $shade1;
        &:hover {
          color: #000;
        }
      }
    }
    @media (max-width: 970px) {
      .flex-space-btw {
        display: block;
        .actions {
          margin-top: 20px;
          @media (max-width: 650px) {
            .ant-space {
              display: block;
              .ant-btn {
                min-width: 80%;
                margin: auto;
                margin-bottom: 10px;
                @media (max-width: 450px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .contactTableContainer {
    border: 1px solid $lightBorder;
    padding: 10px;
    table {
      .ant-table-thead {
        th {
          color: $fade4;
          font-size: 18px;
          font-weight: 400;
        }
      }
      td {
        color: $fade5;
        font-size: 16px;
        font-weight: 400;
        .mdTableActionBtns {
          & button {
            border: 2px solid $lightBorder;
            color: $fade4;
            font-size: 12px;
            border-radius: 4px;
            &:first-child {
              margin-right: 15px;
            }
            &:last-child {
              color: #f45b5b;
            }
            &.allcontact-edit_btn {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }

  .contact_select {
    border-radius: 4px;
    align-items: center;
    color: #717171 !important;
    width: 348px;
  }

  .addGrpBtn {
    height: 34px;
    background: #717171;
    border-radius: 4px;
    max-width: 200px;
    padding: 8px 25px;
    color: #f7f7f7 !important;

    .addGroupText {
      color: #f7f7f7 !important;
      letter-spacing: 0.02em !important;
    }
  }
}
/* Start contact header component*/
.contactHeaderCont {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: #eff8f0;
  margin: 1.938rem;
  padding: 1.6rem 2.25rem 0rem;
  .contactTextPart {
    .contHTitle {
      padding-bottom: 4px;
      color: #45505b;
    }
    .contHDesc {
      // max-width: 478px;
      color: #555555;
      padding-bottom: 1.2rem;
    }
    .contHDescLink {
      color: #273df2;
      padding-bottom: 1.2rem;
      margin-top: 0rem;
    }
    .contactSearchInput {
      max-width: 301px;
    }
  }
  .contactImgPart {
    .contHImg {
      padding-right: 1rem;
    }
  }
}
