@import './variables.scss';

.emmaccen {
  .unsubscribePageContainer {
    .cardContainer {
      min-height: 100vh;
      .card {
        max-width: 400px;
      }
      img {
        height: 200px;
        width: 200px;
      }
    }
  }
}
