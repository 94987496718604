@import './variables.scss';

svg,
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.1em !important;
}
.ant-modal {
  .ant-modal-body {
    padding: 25px 32px;

    .p-6 {
      padding: 0;
    }
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item div {
  font-weight: 500;
  font-size: 15px;
}

.boomshakar {
  box-sizing: border-box;
  -moz-box-sizing: border-box;

  .ant-table-cell .ant-select .ant-select-arrow .anticon {
    content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.81641 1.49902L6.81641 6.49902L11.8164 1.49902' stroke='%2325282B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    display: inline-block;
    width: 20px;
  }

  // .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
  //   content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11 6L6 1L1 6' stroke='%2325282B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  //   display: inline-block;
  // }

  .ant-input-affix-wrapper {
    color: $fade4;
    background-color: $fade6;
    outline: none;
    width: 220px;
    margin-right: 10px;

    &:focus,
    &-focused {
      box-shadow: none;
    }

    .ant-input {
      background-color: $fade6;
    }
  }

  .ant-tabs-nav {
    border: unset;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 6px;
    padding: 0;
    /* margin-top: 20px; */
    /* background-color: #fff; */
    /* padding: 10px 15px; */

    .ant-tabs-ink-bar {
      background-color: $titleDark;
    }
  }

  .ant-table-thead {
    display: none;
  }
  .ant-table-tbody .ant-table-cell {
    border: none;
    padding: 0.5rem;

    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
  }

  .ant-select-selection-item,
  .ant-select-arrow {
    color: $titleDark;
    // font-size: 16px;
    font-weight: 500;
  }

  .ant-collapse,
  .ant-collapse > .ant-collapse-item {
    border: none;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    .ant-collapse-arrow {
      font-weight: 700;
      color: #000;
      font-size: 16px;
    }

    div {
      .team-member-length {
        color: $fade3;
        font-weight: 500;
      }
      button {
        background-color: #f2f2f2;
        color: $fade4;
        font-weight: 500;
      }
    }
  }

  .add-member_team-btn {
    padding: 8px 26px;
    gap: 5px;
    width: 160px;
    height: 35px;
    background: $primaryColor;
    border-radius: 4px;
    color: #fff;
  }

  .admin_team_select_option .ant-select .ant-select-arrow .anticon {
    display: none;
  }
  .admin_team_select_option .ant-select-single .ant-select-selector .ant-select-selection-item {
    color: $fade4;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
  }

  .company_view-member {
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $descriptionTextColor;
    }
    & > div {
      margin-top: 2rem;

      button {
        display: block;
        margin: 10px 0;
        border: 1px solid #ec615b;
        border-radius: 4px;
        padding: 8px 10px;
        color: #ec615b;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.ant-modal-root .ant-modal-centered {
  // top: 10%;
  // vertical-align: text-top;
  border-radius: 20px;

  .ant-modal-content {
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
}

.ant-select-dropdown {
  width: 273px;
  border-radius: 5px;

  .rc-virtual-list-holder {
    // padding: 0.5rem 0;

    .ant-select-item-option {
      padding: 0.6rem;

      .ant-select-item-option-content {
        white-space: pre-wrap;

        div {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 5px;
        }
        p {
          font-size: 12px;
          line-height: 15px;
          color: $descriptionTextColor;

          &.removeUser_from_team {
            color: #ec615b;
          }
        }
      }
    }
  }
}
